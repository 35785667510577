import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import Fuse from "fuse.js";
import { useLocation } from "react-router-dom";
import ResultsComponent from "./ResultsComponent";
import "./css/searchByDrugName.css";
import MyTry from "../../helpers/loading/mytry/myTry";

const SearchByDrugName = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ownId = searchParams.get("id");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [medInputValue, setMedInputValue] = useState("");
  const [medicineChosen, setMedicineChosen] = useState(false);
  const [extractedMedicineNames, setExtractedMedicineNames] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [wholeSales, setWholeSales] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { default: medNamesData } = await import(
          "../../helpers/components/listOfMed"
        );
        const processedMedicineNames = [];
        medNamesData.groupMedicines.forEach((group) => {
          group.medicines.forEach((medicine) => {
            medicine.generalMedicineName.forEach((medicineNameObj) => {
              const { id, name, dosageForms } = medicineNameObj;
              const dosageFormData = dosageForms.map(
                ({ dosageFormId, dosage }) => ({
                  dosageFormId,
                  dosage,
                })
              );
              processedMedicineNames.push({
                id,
                name,
                dosageForms: dosageFormData,
              });
            });
          });
        });
        setExtractedMedicineNames(processedMedicineNames);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (medicineChosen && medInputValue.trim() !== "") {
      const fetchData = async () => {
        let cancel;
        try {
          setLoading(true);
          const response = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL}/pharmacy/searchByDrugName`,
            params: { q: medInputValue, page: pageNumber },
            cancelToken: new axios.CancelToken((c) => (cancel = c)),
            withCredentials: true,
          });
          console.log("🚀 ~ .then ~ res:", response);

          setWholeSales((prevWholeSales) => {
            return [
              ...new Set([
                ...prevWholeSales,
                ...response.data.data.map((b) => ({
                  companyName: b.companyName,
                  id: b.id,
                  phoneNumber: b.phoneNumber,
                })),
              ]),
            ];
          });

          setHasMore(response.data.data.length > 0);
          setError(null);
        } catch (error) {
          if (axios.isCancel(error)) return;
          console.error("Error fetching data:", error);
          setError("Error fetching data. Please try again.");
        } finally {
          setLoading(false);
        }
        return () => cancel();
      };

      fetchData();
    } else {
      setWholeSales([]);
      setError(null);
    }
  }, [medicineChosen, medInputValue, pageNumber]);

  const handleMedSelect = (selectedOption, e) => {
    e.preventDefault();
    setMedInputValue(selectedOption.name);
    setMedicineChosen(true);
    setFilteredOptions([]);
    setPageNumber(1); // Reset the page number when a medicine is chosen
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue !== medInputValue) {
      setPageNumber(1); // Reset the page number if the input value changes
    }
    setMedInputValue(inputValue);
    setMedicineChosen(false);

    if (inputValue) {
      const fuse = new Fuse(extractedMedicineNames, {
        keys: ["name"],
        // threshold: 0.3,
        includeScore: true,
      });
      const result = fuse.search(inputValue);
      const uniqueOptions = result.map(({ item }) => item);
      setFilteredOptions(uniqueOptions);
    } else {
      setFilteredOptions([]);
    }
  };

  const handleSearch = () => {
    if (!medicineChosen) {
      setError("Please choose a medicine from the list.");
    } else {
      setError(null);
      // Trigger the fetchData useEffect
      setMedicineChosen(true);
    }
  };

  return (
    <div className="search-container">
      <h1>Search By Medicine Name</h1>
      <input
        type="text"
        placeholder="Enter drug name"
        value={medInputValue}
        onChange={handleInputChange}
      />
      <button onClick={handleSearch}>Search</button>
      {loading && <MyTry/>}
      {error && <p>{error}</p>}
      {filteredOptions.length > 0 && (
        <div className="search-results">
          <ul>
            {filteredOptions.map((option, index) => (
              <li key={index} onClick={(e) => handleMedSelect(option, e)}>
                {option.name}
              </li>
            ))}
          </ul>
        </div>
      )}
      {wholeSales.length > 0 && (
        <div className="wholesales-section">
          <h2>Wholesales</h2>
          <ul>
            {wholeSales.map((wholesale, index) => {
              if (wholeSales.length === index + 1) {
                return (
                  <div ref={lastElementRef} key={wholesale.id}>
                    <ResultsComponent wholeSale={wholesale} ownId={ownId} />
                  </div>
                );
              } else {
                return (
                  <div key={wholesale.id}>
                    <ResultsComponent wholeSale={wholesale} ownId={ownId} />
                  </div>
                );
              }
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchByDrugName;
