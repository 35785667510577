import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import MyTry from "../helpers/loading/mytry/myTry";
import "./CSS/wholeSalesOrderNotification.css";
import PopupResonForDiduction from "../POPUPS/DeductionsfromStock/resonForDiduction";

const OrderNotification = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ownId = searchParams.get("id");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [selectedReason, setSelectedReason] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [removedOrders, setRemovedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const options = [
    "sold_door_to_door",
    "Institution",
    "Incentive",
    "f_s",
    "Transfer",
    "Damage",
    "Loss",
    "EFDA_QT",
    "Expired_on_hand",
    "Exchange_for_expired",
    "Unknown",
  ];

  useEffect(() => {
    const fetchOrders = async () => {
      const fetchUrl = `${process.env.REACT_APP_API_URL}/wholeSale/orders?id=${ownId}`;
      try {
        const response = await axios.get(fetchUrl, {
          withCredentials: true, // Enable sending cookies with the request
        });
        console.log("🚀 ~ fetchOrders ~ response:", response.data);
        setOrderData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [ownId]);
  const removeOrder = () => {
    if (currentOrder && selectedReason) {
      const { date, userId, order } = currentOrder;
      console.log("🚀 ~ removeOrder ~ order:", order);
      const updatedOrderData = { ...orderData };
      const to_be_removed = order.to_be_rmoved; // Remember the value
      const medicineId = order.medicine_id; // Remember the value
      const amountSold = order.amount_sold; // Remember the value
      // Update order data by removing the order
      updatedOrderData[date][userId].orders = updatedOrderData[date][
        userId
      ].orders.filter((o) => o !== order);
      setOrderData(updatedOrderData);

      // Update removed orders state with additional information
      setRemovedOrders((prevRemovedOrders) => ({
        ...prevRemovedOrders,
        [`${date}-${userId}-${order.batchNum}`]: {
          date,
          userId,
          to_be_removed,
          medicineId,
          amountSold,
          reason: selectedReason,
        },
      }));

      setCurrentOrder(null);
      setSelectedReason("");
      setIsPopupOpen(false); // Close the popup
    }
  };

  const logOrderData = (date, userId, userData) => {
    const totalAmountSold = userData.orders.reduce(
      (acc, order) => acc + order.amount_sold * order.unit_price,
      0
    );

    // Find all removed orders for the specific user and date
    const removedOrdersForUser = Object.values(removedOrders).filter(
      (order) => order.userId === userId && order.date === date
    );
    const removedOrderDetails = removedOrdersForUser.map((order) => ({
      to_be_removed: order.to_be_removed,
      medicineId: order.medicineId,
      amountSold: order.amountSold,
      reason: order.reason,
    }));

    console.log("Removed Order Numbers:", removedOrderDetails);
    console.log("Order Data:", userData);
    const formDataToSend = {
      pharmacyId: userId,
      ownId: ownId,
      acceptedOrder: userData,
      removeOrders: removedOrderDetails,
      date: date,
      totalAmountSold: totalAmountSold,
    };
    console.log("Form Data:", formDataToSend);

    const submitUrl = `${process.env.REACT_APP_API_URL}/wholesale/acceptedOrder`;

    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Kindly ensure confirmation with the customer via a phone call prior to proceeding."
    );

    if (isConfirmed) {
      axios
        .post(submitUrl, formDataToSend)
        .then((res) => {
          console.log("ORDER SET SUCCESSFUL");
          if (res.data.Status === "Success") {
            console.log("Order successful");
            window.location.reload();
          } else {
            alert("unable to register");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("An error occurred during setting order");
        });
    } else {
      console.log("Order submission was cancelled by the user.");
    }
  };
  const handleOrderRemoveClick = (date, userId, order) => {
    setCurrentOrder({ date, userId, order });
    setIsPopupOpen(true);
  };

  // Function to calculate the total amount for all products within user's orders
  const calculateTotalAmount = (userOrders) => {
    let totalAmount = 0;
    userOrders.orders.forEach((order) => {
      totalAmount += order.amount_sold * order.unit_price;
    });
    return totalAmount;
  };

  // Function to calculate the total amount for all products across all user orders
  const calculateOverallTotal = () => {
    let overallTotal = 0;
    Object.values(orderData).forEach((userData) => {
      Object.values(userData).forEach((userOrders) => {
        overallTotal += calculateTotalAmount(userOrders);
      });
    });
    return overallTotal;
  };

  return (
    <div className="sellerOrders">
      <h1>Pending Orders</h1>
      <button>
        <Link to={`/wholeSale/msorders?id=${ownId}`}>
          See Orders For Medical Supply{" "}
        </Link>
      </button>
      {loading && <MyTry className="loading" />}
      {error && <p className="error">Error: {error}</p>}
      {!loading && Object.keys(orderData).length === 0 && (
        <p>No orders found.</p>
      )}
      {!loading && Object.keys(orderData).length > 0 && (
        <ul className="orderList">
          {Object.entries(orderData).map(([date, userData]) => (
            <li key={date} className="orderItem">
              <h2 className="orderDate">Date: {date}</h2>
              <ul className="userList">
                {Object.entries(userData).map(([userId, userOrders]) => (
                  <li key={userId} className="userItem">
                    <Link to={`/wholeSale/phProfile?id=${userId}`}>
                      <h4 className="companyName">
                        Company Name: {userOrders.company_name}
                      </h4>
                    </Link>
                    <h4 className="companyName">
                      Company Type: {userOrders.company_type}
                    </h4>
                    <h4 className="phoneNumber">
                      Phone Number: {userOrders.phone_number}
                    </h4>
                    <p>
                      Total Amount Sold:
                      {userOrders.orders.reduce(
                        (acc, order) =>
                          acc + order.amount_sold * order.unit_price,
                        0
                      )}
                    </p>
                    <button
                      onClick={() => logOrderData(date, userId, userOrders)}
                    >
                      Accept Order
                    </button>

                    <ul className="orderDetails">
                      {userOrders.orders.map((order, index) => (
                        <li key={index} className="orderDetail">
                          <p>
                            <u>
                              <b>Drug Name: {order.drug_name}</b>
                            </u>
                          </p>
                          <p>Dosage: {order.dosage}</p>
                          <p>Batch Number: {order.batchNum}</p>
                          <p>Expiration Date: {order.drug_expdate}</p>
                          <p>Brand Name: {order.brand_name}</p>
                          <p>Unit Price: {order.unit_price}</p>
                          <p>Unit of Issue: {order.unitOfIssue}</p>
                          <p>
                            Amount Sold: <b>{order.amount_sold}</b>
                          </p>
                          <p>
                            Total Amount Sold:
                            {order.amount_sold * order.unit_price}
                          </p>
                          <button
                            onClick={() =>
                              handleOrderRemoveClick(date, userId, order)
                            }
                          >
                            Remove Order
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          ))}
          <li className="overallTotalItem">
            <p>Overall Total: {calculateOverallTotal()}</p>
          </li>
        </ul>
      )}
      {/* Popup component */}
      <PopupResonForDiduction
        isOpen={isPopupOpen}
        options={options}
        onClose={() => setIsPopupOpen(false)}
        onSelect={(reason) => {
          setSelectedReason(reason);
          removeOrder(); // Remove the order and set data
        }}
      />
    </div>
  );
};

export default OrderNotification;
