import React, { createContext, useState, useEffect } from "react";

export const ShopContext = createContext(null);

export const ShopContextProvider = (props) => {
  const [producted, setProducted] = useState([]); // Initialize producted state with an empty array
  const [cartItems, setCartItems] = useState({});
  console.log("🚀 ~ ShopContextProvider ~ producted:", producted);

  useEffect(() => {
    // Set the cart items once the producted state is set
    setCartItems(getDefaultCart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [producted]);

  const setProductData = (data) => {
    // If data is empty or undefined, simply return

    // Retain the previous cart items' IDs and amounts ordered
    const updatedCartItems = {};
    for (const item of data) {
      const previousAmount = cartItems[item.id] || 0;
      updatedCartItems[item.id] = previousAmount;
    }
    setCartItems(updatedCartItems);

    setProducted(data); // Function to set the producted state
  };
  // Add this new function to reset cart items and products
  const resetCartAndProducts = () => {
    setProducted([]);
    setCartItems({});
    console.log("products are made to be empty getting new shops product");
  };
  const getProductedData = () => {
    return producted;
  };
  const getDefaultCart = () => {
    let cart = {};
    for (let i = 0; i < producted.length; i++) {
      cart[producted[i].id] = 0;
    }
    return cart;
  };

  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const items in cartItems) {
      if (cartItems[items] > 0) {
        let itemInfo = producted.find(
          (product) => product.id === Number(items)
        );
        if (itemInfo) {
          const itemPrice = itemInfo.price;
          const itemCount = cartItems[items];
          const subtotal = itemCount * itemPrice;
          console.log(
            `Item ${items}: Price - ${itemPrice}, Count - ${itemCount}, Subtotal - ${subtotal}`
          );
          totalAmount += subtotal;
        } else {
          console.error(`Item ${items} not found in product list`);
        }
      }
    }
    return totalAmount;
  };
  const addToCart = (itemId) => {
    console.log("🚀 ~ addToCart ~ itemId:", itemId)
    // Find the product corresponding to the itemId
    const product = producted.find((product) => product.id === itemId);

    if (!product) {
      console.error(`Product with ID ${itemId} not found.`);
      return;
    }

    // Check if adding 1 to the cart exceeds the available stock
    if (cartItems[itemId] + 1 > product.stockQuantity) {
      console.log(
        `Cannot add more of ${product.productName} to the cart. Maximum available stock reached.`
      );
      return;
    }
    // Update the cart with the incremented quantity
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
    console.log(itemId, "this here");
  };
  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
  };
  const updateCartItemCount = (newAmount, itemId) => {
    // Find the product corresponding to the itemId
    const product = producted.find((product) => product.id === itemId);

    if (!product) {
      console.error(`Product with ID ${itemId} not found.`);
      return;
    }

    // Check if the new amount exceeds the stock quantity
    if (newAmount > product.stockQuantity) {
      console.log(
        `Cannot update the cart. The new amount exceeds the available stock quantity (${product.stockQuantity}) for ${product.productName}.`
      );
      newAmount = product.stockQuantity;
    }

    // Update the cart with the new amount
    setCartItems((prev) => ({ ...prev, [itemId]: newAmount }));
  };
  console.log("cartItems", cartItems);

  const contextValue = {
    cartItems,
    addToCart,
    removeFromCart,
    updateCartItemCount,
    getTotalCartAmount,
    setProductData,
    getProductedData,
    resetCartAndProducts,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};
