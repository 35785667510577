import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaPhone, FaBookMedical, FaRegFilePdf } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import {
  TbReceiptTax,
  TbLogout,
  // TbPasswordUser
} from "react-icons/tb";
import { TiArrowBack } from "react-icons/ti";
import { CiSettings } from "react-icons/ci";
import "./css/pharmacysProfile.css";
import MyTry from "../../helpers/loading/mytry/myTry";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <p>Oops! Something went wrong.</p>; // Render a fallback UI
    }

    return this.props.children;
  }
}

const PhProfilePage = () => {
  const [isEditing, setEditing] = useState(false);
  const [companyName, setCompanyName] = useState("Your Company Name");
  const [phoneNumber, setPhoneNumber] = useState("091-234-5678");
  const [licenseNo, setLicenseNo] = useState("Your License Number");
  const [tinNo, setTinNo] = useState("Your Tin Number");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      // Update the state inside the useEffect hook
      setCompanyName(data.companyName);
      setPhoneNumber(data.phoneNumber);
      setLicenseNo(data.licenceNumber);
      setTinNo(data.tinNumber);
    }
  }, [data]); // This will update the state when 'data' changes

  const handleSetting = () => {
    navigate(`/pharmacy/deleteAccount?id=${id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchUrl = `${process.env.REACT_APP_API_URL}/pharmacy/profile?id=${id}`;
      try {
        const response = await axios.get(fetchUrl, {
          withCredentials: true, // Enable sending cookies with the request
        });
        setData(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setError(true);
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const handleEdit = () => {
    setEditing(!isEditing);
  };

  const handleApplyChanges = async () => {
    try {
      const submitUrl = `${process.env.REACT_APP_API_URL}/pharmacy/profile/userInfoupdate`;
      const response = await axios.put(
        submitUrl,
        {
          CompanyName: companyName,
          PhoneNumber: phoneNumber,
          LicenseNo: licenseNo,
          TinNo: tinNo,
          userId: id,
        },
        {
          withCredentials: true,
        }
      );

      if (response.data.Status === "Success") {
        console.log("Profile  updated successfully");
      } else {
        setError("Unable to update profile");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Reset isEditing to false regardless of success or error
      setEditing(false);
    }
  };

  const logout = async () => {
    const leaveUrl = `${process.env.REACT_APP_API_URL}/logout`;

    try {
      const response = await axios.post(
        leaveUrl,
        {},
        {
          withCredentials: true, // Enable sending cookies with the request
        }
      );
      console.log("🚀 ~ logout ~ response:", response);

      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
      // Handle error, maybe show a message to the user
    }
  };

  if (error) {
    return (
      <div className="error">
        <h1>Error while fetching the profile</h1>
        <Link to={`/pharmacy/phhome?id=${id}`}>
          <h2>
            <TiArrowBack />
            go back to the Home
          </h2>
        </Link>
      </div>
    );
  }

  if (isLoading) {
    return <MyTry />;
  }

  if (!data) {
    return (
      <div className="error">
        <h1>Couldn't fetch user information</h1>
        <Link to="/login">
          <h2>Try logging in again</h2>
        </Link>
      </div>
    );
  }

  return (
    <div className="profile-page">
      <h1>Profile Page</h1>

      <button className="go-back-btn" onClick={handleSetting}>
        <CiSettings />
        Setting
      </button>
      <ErrorBoundary>
        <div className="profile-info">
          <div className="info-row">
            <label> Company Name: </label> <h4>{id}</h4>
            {isEditing ? (
              <input
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className="profile-input"
              />
            ) : (
              <span>{companyName}</span>
            )}
          </div>
          <div className="info-row">
            <label>
              <FaPhone /> Phone Number:
            </label>
            {isEditing ? (
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="profile-input"
              />
            ) : (
              <span>{phoneNumber}</span>
            )}
          </div>
          <div className="info-row">
            <label>
              <FaBookMedical /> License No:
            </label>
            {isEditing ? (
              <input
                type="text"
                value={licenseNo}
                onChange={(e) => setLicenseNo(e.target.value)}
                className="profile-input"
              />
            ) : (
              <span>{licenseNo}</span>
            )}
          </div>
          <div className="info-row">
            <label>
              <TbReceiptTax /> Tin No:
            </label>
            {isEditing ? (
              <input
                type="text"
                value={tinNo}
                onChange={(e) => setTinNo(e.target.value)}
                className="profile-input"
              />
            ) : (
              <span>{tinNo}</span>
            )}
          </div>
        </div>

        <div className="actions">
          <button className="edit-btn" onClick={handleEdit}>
            {isEditing ? "Cancel" : "Edit Profile"}
          </button>
          {isEditing && (
            <button className="apply-btn" onClick={handleApplyChanges}>
              Apply Changes
            </button>
          )}
          <div className="button-group">
            <Link to={`/pharmacy/phProfileChangelicence?id=${id}`}>
              <button className="profile-action-btn">
                <FaRegFilePdf /> Check/Change License Picture
              </button>
            </Link>
            <Link to={`/pharmacy/phProfileChangelocation?id=${id}`}>
              <button className="profile-action-btn">
                <FaLocationDot /> Check/Change location or Adress
              </button>
            </Link>
            {/* <Link to={`/pharmacy/phProfileChangelicence?id=${id}`}>
              <button className="profile-action-btn">
                 <TbPasswordUser /> {
                Check/Change user name and password
              </button>
            </Link> */}

            <button onClick={() => logout()}>
              <TbLogout />
              Logout
            </button>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default PhProfilePage;
