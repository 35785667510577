import React, { useState, useEffect } from "react";
import { CiDeliveryTruck } from "react-icons/ci";
import { FaStore } from "react-icons/fa";
import { useLocation, Link } from "react-router-dom";

import axios from "axios";
import OrderDetail from "./components/manageYourOrdersComponent";
import "./CSS/manageYourOrders.css";

const MsOrderPageManager = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("id");
  console.log("🚀 ~ MsOrderPageManager ~ userId:", userId);
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchUrl = `${process.env.REACT_APP_API_URL}/pharmacy/msgetMeMyOrders?id=${userId}`;
      try {
        const response = await axios.get(fetchUrl, {
          withCredentials: true, // Enable sending cookies with the request
        });
        // Check if response.data.data is an array
        if (Array.isArray(response.data.data)) {
          console.log("this are your orders", response.data.data);
          setOrders(response.data.data);
        } else {
          console.error("Error: Response data is not an array");
        }
        console.log(response, "HERE IN THE order Manager ");
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [userId]);
  return (
    <div className="orders-container">
      <h1 className="page-title">Your Orders</h1>
      <div className="orders-grid">
        {orders.map((order) => (
          <div key={order.date} className="order-card">
            <Link
              to={`/pharmacy/forPHWholeSalesProfile?wholeSalesId=${order.userId}&wholeSaleName=${order.orders[0].companyName}`}
              className="order-link"
            >
              <h2 className = "shop-name">
                Shop: {order.orders[0].companyName}
                <FaStore className="icon" />
              </h2>
              <p className = "phone-number">
                Phone Number: {order.orders[0].phoneNumber1}
              </p>
            </Link>
            <p className = "total-amount">
              Total Amount: $
              {order.orders
                .reduce((acc, curr) => acc + curr.amountPerProduct, 0)
                .toFixed(2)}
            </p>
            <p className="order-date">Ordered Time: {order.date}</p>
            <Link
              to={`/pharmacy/orderForDelivery?pharmId=${userId}&wholeSaleId=${order.userId}`}
              className="delivery-link"
            >
              Order For Delivery
              <CiDeliveryTruck className="icon" />
            </Link>
            <button className="view-products-button">
              <OrderDetail additionalData={order} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MsOrderPageManager;
