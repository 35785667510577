import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";
import FileDownload from "js-file-download";
import {
  isValidFileType,
  isValidFileSize,
  generateUniqueFileName,
} from "../../../helpers/fileUtils"; // Adjust the path
import "./CSS/licence.css";
import MyTry from "../../../helpers/loading/mytry/myTry";

const WsProfileLicencePage = () => {
  // this was helpful when i pass it in the query parameter

  const [licenceForTb, setLicenceForTb] = useState("");
  const [licenceForGibir, setLicenceForGibir] = useState("");
  const [filenameOfTb, setFilenameOfTb] = useState("");
  const [filenameOfGbr, setFilenameOfGbr] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [updated, setUpdated] = useState("no changes detected (not updated)");
  useEffect(() => {
    if (data) {
      // Update the state inside the useEffect hook

      setFilenameOfTb(data.filenameOfTb);
      setFilenameOfGbr(data.filenameOfGb);
    }
  }, [data]); // This will update the state when 'data' changes

  const handleGoBack = () => {
    window.history.back(); // This navigates back in the browser's history.
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log("🚀 ~ fetchDataa ~ id:", id);
      const fechUrl = `${process.env.REACT_APP_API_URL}/wholesale/profile/licenceName?id=${id}`;
      try {
        // Set isLoading to true when starting to fetch data
        setIsLoading(true);
        const response = await Axios.get(fechUrl, {
          withCredentials: true, // Enable sending cookies with the request
        });
        // Check if the response data is empty
        if (response.data.Status === "unsuccess") {
          console.log("Response is empty", id);
          console.log("🚀 ~ fetchData ~ { state: { data: id } }:", {
            state: { data: id },
          });

          navigate("/wholeSale/signUpUploadws", { state: { data: id } });
          // Handle the case where the response is empty
        } else {
          // Handle the case where the response is not empty
          setData(response.data.data);
          console.log(response, "HERE IN THE PROFILE LICENCE PAGE ");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setError(true);
        setIsLoading(false);
      } finally {
        // Set isLoading to false regardless of success or error
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [id, navigate]);
  const handleFile1Change = (event) => {
    const file1 = event.target.files[0];
    if (file1) {
      if (!isValidFileType(file1) || !isValidFileSize(file1)) {
        setError(
          "Invalid licence. Please select a valid file (JPEG, JPG, PNG, GIF, or PDF) less than 1MB."
        );
        setLicenceForTb(null);
      } else {
        // Change the file name here (e.g., appending a unique identifier)

        const newFileName = generateUniqueFileName(file1.name, "LFTB", id);
        const renamedFile = new File([file1], newFileName, {
          type: file1.type,
        });
        setLicenceForTb(renamedFile);
        setError("");
      }
    }
  };

  const handleFile2Change = (event) => {
    const file2 = event.target.files[0];
    if (file2) {
      if (!isValidFileType(file2) || !isValidFileSize(file2)) {
        setError(
          "Invalid Revenue. Please select a valid file (JPEG, JPG, PNG, GIF, or PDF) less than 1MB."
        );
        setLicenceForGibir(null);
      } else {
        // Change the file name here (e.g., appending a unique identifier)
        const newFileName = generateUniqueFileName(file2.name, "LFGB", id);
        const renamedFile = new File([file2], newFileName, {
          type: file2.type,
        });
        setLicenceForGibir(renamedFile);
        setError("");
      }
    }
  };

  Axios.defaults.withCredentials = true;

  const handleViewFile1 = async (e) => {
    console.log(
      "this will be sent to the api bc we need a file with this name",
      filenameOfTb
    );
    e.preventDefault();
    setIsLoading(true);
    const file1DownloadUrltest = `${process.env.REACT_APP_API_URL}/wholesale/profile/licencedownload/file1?filenameOfTb=${filenameOfTb}`;

    try {
      const response = await Axios.get(file1DownloadUrltest, {
        responseType: "blob",
        withCredentials: true,
      });

      // Check if the response contains the file
      if (response.data) {
        FileDownload(response.data, filenameOfTb);
        setUpdated("YOU GOT THE FILE SUCCESSFULLY");
      } else {
        console.error("No file data in the response.");
        // Handle this case according to your requirements
      }
    } catch (error) {
      if (error.response && error.response.statusText === 404) {
        setUpdated(
          "you dont seem to have a licence in the data base please upload one"
        );
      } else {
        setError("Error downloading file 2:", error);
        console.log(error);
      }
    } finally {
      // Set isLoading to false after the API response is received
      setIsLoading(false);
    }
  };
  const handleViewFile2 = async (e) => {
    console.log(
      "this will be sent to the api bc we need a file with this name",
      filenameOfGbr
    );
    e.preventDefault();
    setIsLoading(true);
    const file2DownloadUrltest = `${process.env.REACT_APP_API_URL}/wholesale/profile/licencedownload/file2?filenameOfGbr=${filenameOfGbr}`;

    try {
      const response = await Axios.get(file2DownloadUrltest, {
        responseType: "blob",
        withCredentials: true,
      });

      // Check if the response contains the file
      if (response.data) {
        FileDownload(response.data, filenameOfGbr);
        setUpdated("YOU GOT THE FILE SUCCESSFULLY");
      } else {
        console.error("No file data in the response.");
        // Handle this case according to your requirements
      }
    } catch (error) {
      if (error.response && error.response.statusText === 404) {
        setUpdated(
          "you dont seem to have a licence in the data base please upload one"
        );
      } else {
        setError("Error downloading file 2:", error);
        console.log(error);
      }
    } finally {
      // Set isLoading to false after the API response is received
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!licenceForTb || !licenceForGibir) {
      setError("Please select both files to upload.");
      return;
    }
    // Send the form data to the server
    const formData = new FormData();
    formData.append("licenceForTbws", licenceForTb);
    formData.append("licenceForGibirws", licenceForGibir);
    formData.append("id", id);
    formData.append("filenameOfTb", filenameOfTb);
    formData.append("filenameOfGbr", filenameOfGbr);
    console.log(
      "licenceForTb",
      licenceForTb,
      "licenceForGibir",
      licenceForGibir
    );
    const submitUrl = `${process.env.REACT_APP_API_URL}/wholesale/profile/licenceupdate`;
    try {
      const res = await Axios.put(submitUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Request sent successfully");
      if (res.data.Status === "Success") {
        console.log("Registration successful");
        setUpdated("updated successfully");
        setIsLoading(false);
      } else {
        setError("Unable to register");
        setUpdated("Unable to update please try again");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred during registration. Please try again.");
    } finally {
      // Set isLoading to false regardless of success or error
      setIsLoading(false);
    }
  };

  if (error) {
    console.error("Error:", error);
    return (
      <div>
        <h1>Error while fetching the profile</h1>
        <h1>{error}</h1>
        <Link to="/wholeSale/wshome">
          <h2>go back to the home page </h2>
        </Link>
      </div>
    );
  }
  if (isLoading) {
    return <MyTry />;
  }
  return (
    <div class="container">
      <h1 class="page-heading">File Upload and Download</h1>
      <button class="go-back-button" onClick={handleGoBack}>
        Go Back
      </button>
      <div class="file-actions">
        <div class="file-group">
          <button class="view-file-button" onClick={handleViewFile1}>
            View tena biro licence
          </button>
          <button class="view-file-button" onClick={handleViewFile2}>
            View Revenue licence
          </button>
        </div>

        <div class="file-group">
          {/* <button onClick={handleViewFile2}>View File 2</button> */}
          <input class="file-input" type="file" onChange={handleFile1Change} />
          <span class="file-placeholder">Upload tena biro licence</span>
        </div>
        <div class="file-group">
          {/* <button onClick={handleViewFile2}>View File 2</button> */}
          <input class="file-input" type="file" onChange={handleFile2Change} />
          <span class="file-placeholder">Upload gbr licence</span>
        </div>

        <button class="upload-button" onClick={handleSubmit}>
          Upload Files
        </button>
        <h4 class="status-message">{updated}</h4>
      </div>
    </div>
  );
};

export default WsProfileLicencePage;
