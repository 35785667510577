// fileUtils.js

export const isValidFileType = (file) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'application/pdf'];
    return allowedTypes.includes(file.type);
  };
  
  export const isValidFileSize = (file) => {
    return file.size <= 1024 * 1024 * 2; // 1MB limit
  };
  
  export const generateUniqueFileName = (originalName, identifier, userId) => {
    console.log("🚀 ~ generateUniqueFileName ~ userId:", userId)
    console.log("🚀 ~ generateUniqueFileName ~ identifier:", identifier)
    console.log("🚀 ~ generateUniqueFileName ~ originalName:", originalName)
    const timestamp = Date.now();
    const fileExtension = originalName.split('.').pop();
    console.log('In the frontend, this will be its name', identifier, userId, timestamp);
    return `${identifier}_${userId}_${timestamp}.${fileExtension}`;
  };
  