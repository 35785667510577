import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./css/bankForWs.css";
// Import logos
import abayLogo from "../../../../helpers/bankLogos/AbayBank.webp";
import addisInternationalLogo from "../../../../helpers/bankLogos/adis-international_n-128x128.png";
import ahaduLogo from "../../../../helpers/bankLogos/ahadu-logo-128x128.jpeg";
import BankofAbyssinia from "../../../../helpers/bankLogos/abyssinia-bank-logo-profit-financial-statement-shareholder-e1685430860462-128x100.jpg";
import AmharaBank from "../../../../helpers/bankLogos/Amhara-bank-to-start-operation-sene-11-2014-2-128x128.jpg";
import AwashBank from "../../../../helpers/bankLogos/awash-bank-1.webp";
import BerhanBank from "../../../../helpers/bankLogos/berhan-bank.jpg";
import BunaBank from "../../../../helpers/bankLogos/bunna-bank-logo-128x128.webp";
import Cbe from "../../../../helpers/bankLogos/cbe-1-128x128.png";
import Coop from "../../../../helpers/bankLogos/coop-128x128.jpg";
import DashenBank from "../../../../helpers/bankLogos/dashen-bank-128x128.png";
import EnatBank from "../../../../helpers/bankLogos/enat-bank-128x128.png";
import GadaaBank from "../../../../helpers/bankLogos/gada-bank-small-128x128.webp";
import GlobalBank from "../../../../helpers/bankLogos/global-bank-128x128.webp";
import HibretBank from "../../../../helpers/bankLogos/hibret-Bank-128x128.png";
import HijraBank from "../../../../helpers/bankLogos/hijra-bank-128x128.webp";
import LionBank from "../../../../helpers/bankLogos/lion-bank-o-128x128.jpg";
import NibBank from "../../../../helpers/bankLogos/nib-bank-1-128x128.jpg";
import OromiaBank from "../../../../helpers/bankLogos/OROMIABANK-LOGO-1-128x128.webp";
import ShebelleBank from "../../../../helpers/bankLogos/shabelle-bank-1200x630wa-128x128.webp";
import SiinqeeBank from "../../../../helpers/bankLogos/siinquee-bankfor-pc-128x128.webp";
import TsedeyBank from "../../../../helpers/bankLogos/tsedey-bank2-128x128.webp";
import TsehayBank from "../../../../helpers/bankLogos/tsehay-bank4-128x128.webp";
import WegagenBank from "../../../../helpers/bankLogos/wegagen-bank-logo-300x233-1-128x128.png";
import ZamzamBank from "../../../../helpers/bankLogos/zamzam-bank-logo-1-128x128.jpg";
import ZemenBank from "../../../../helpers/bankLogos/zemen-bank-128x128.webp";
import MyTry from "../../../../helpers/loading/mytry/myTry";

const logoMap = {
  Abay_Bank: abayLogo,
  Addis_International_Bank: addisInternationalLogo,
  Ahadu_Bank: ahaduLogo,
  Amhara_Bank: AmharaBank,
  Bank_of_Abyssinia: BankofAbyssinia,
  Awash_Bank: AwashBank,
  Berhan_Bank: BerhanBank,
  Bunna_Bank: BunaBank,
  Commercial_Bank_of_Ethiopia_CBE: Cbe,
  Cooperative_Bank_of_Oromia: Coop,
  Dashen_Bank: DashenBank,
  Enat_Bank: EnatBank,
  Gadaa_Bank: GadaaBank,
  Global_Bank_Ethiopia: GlobalBank,
  Hibret_Bank: HibretBank,
  Hijra_Bank: HijraBank,
  Lion_Bank: LionBank,
  Nib_Bank: NibBank,
  Oromia_Bank: OromiaBank,
  Shabelle_Bank: ShebelleBank,
  Siinqee_Bank: SiinqeeBank,
  Tsedey_Bank: TsedeyBank,
  Tsehay_Bank: TsehayBank,
  Wegagen_Bank: WegagenBank,
  ZamZam_Bank: ZamzamBank,
  Zemen_Bank: ZemenBank,
};

const BankDisplay = () => {
  const [banks, setBanks] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isNew, setIsNew] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const WsId = queryParams.get("WsId");
  const WsName = queryParams.get("WsName");
  console.log("🚀 ~ BankDisplay ~ WsName:", WsName)

  useEffect(() => {
    const fetchData = async () => {
      const fetchUrl = `${process.env.REACT_APP_API_URL}/wholesale/manageBanks?id=${WsId}`;
      try {
        const response = await axios.get(fetchUrl, { withCredentials: true });
        if (response.data.data.length === 0) {
          setIsNew(true);
        } else {
          const bankData = response.data.data[0];
          setBanks(bankData);
        }
        setLoading(false);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setIsNew(true);
        } else {
          setError(err.message);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [WsId]);

  if (loading) return <MyTry />;
  if (error) return <p className="error-message">Error: {error}</p>;

  return (
    <form className="bank-form">
      <h1>
        {isNew
          ? "They don't seem to have their account detail with us"
          : "Account Owners Name Should Be The Company Name"}{" "}
        <b>{WsName}</b>
      </h1>
      {Object.keys(banks).map((bankKey) => {
        if (bankKey === "id" || bankKey === "user_id") return null; // Exclude id and user_id
        return (
          <div className="bank-entry" key={bankKey}>
            {logoMap[bankKey] && (
              <div className="bank-logo">
                <img src={logoMap[bankKey]} alt={`${bankKey} logo`} />
              </div>
            )}
            <label>
              {bankKey.replace(/_/g, " ")}
              <input
                type="text"
                name={bankKey}
                value={banks[bankKey] || ""}
                placeholder={`They don't have account with ${bankKey}`}
              />
            </label>
          </div>
        );
      })}
    </form>
  );
};

export default BankDisplay;
