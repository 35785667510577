import React from "react";

const AccountDeletionPage = () => {
  return (
    <div style={styles.container}>
      <h1>Account Deletion - JabezRemedy</h1>

      <section style={styles.section}>
        <h2>Steps to Request Account Deletion</h2>
        <ol>
          <li>
            Log in to your account at
            <a href="https://www.jabezremedy.com">JabezRemedy.com</a>.
          </li>
          <li>Go to your profile page.</li>
          <li>Go to Setting.</li>
          <li>Click on the "Delete My Account" button.</li>
          <li>Confirm your decision when prompted.</li>
        </ol>
        <p>
          After confirming, your data will be held for a week, and transaction
          history will be retained for 7 years for legal compliance.
        </p>
      </section>

      <section style={styles.section}>
        <h2>Data Deletion Policy</h2>
        <p>
          <strong>Deleted Data:</strong> Your profile info, user preferences,
          and order history will be deleted.
        </p>
        <p>
          <strong>Retained Data:</strong> Your transaction history will be
          retained for 7 years for legal reasons. Even if you don't delete your
          transaction history, it will be deleted after 10 years.
        </p>
      </section>

      <section style={styles.section}>
        <h2>Legal Compliance</h2>
        <p>We comply with data protection regulations, including:</p>
        <ul>
          <li>
            <strong>GDPR</strong> - General Data Protection Regulation for EU
            residents.
          </li>
          <li>
            <strong>CCPA</strong> - California Consumer Privacy Act for
            California residents.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or need assistance, please reach out to us:
        </p>
        <p>
          Email:
          <a href="mailto:naol@jabezremedy.com">naol@jabezremedy.com</a>
        </p>
        <p>Phone: +251 93 596 8129</p>
      </section>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "rgba(173, 216, 230, 0.7)", // Light blue with transparency
    padding: "20px",
    borderRadius: "8px",
    fontFamily: "Arial, sans-serif",
    color: "black",
  },
  section: {
    marginBottom: "20px",
    padding: "15px",
    backgroundColor: "white",
    borderRadius: "5px",
  },
};

export default AccountDeletionPage;
