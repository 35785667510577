/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from "react";
import searchByForWs from "./searchByForWs";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/invoicesFor.css";
import MyTry from "../../../helpers/loading/mytry/myTry";

export default function MsInvoiceSearchByGeneralWs() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ownId = searchParams.get("id");
  const [query, setQuery] = useState("");
  const [sortField, setSortField] = useState("sold_on");
  const [sortOrder, setSortOrder] = useState("asc");
  const [pageNumber, setPageNumber] = useState(1);
  const { invoices, hasMore, loading, error } = searchByForWs(
    query,
    sortField,
    sortOrder,
    pageNumber,
    ownId
  );
  const observer = useRef();
  const navigate = useNavigate();

  console.log("🚀 ~ InvoiceSearchByGeneral ~ :", invoices);

  const lastInvoiceElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    setQuery(e.target.value);
    setPageNumber(1);
  }

  function handleSort(field) {
    const newSortOrder =
      sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newSortOrder);
    setPageNumber(1);
  }

  function handleInvoiceClick(invoice) {
    navigate("/msinvoicePrint", {
      state: { data: invoice.invoiceNumber },
    });
  }
  return (
    <div className="invoices-container">
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        placeholder="Search invoices..."
        className="search-input"
      />
    
      <table className="invoices-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("invoice_num")}>
              Invoice Number
              {sortField === "invoice_num" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("sold_on")}>
              Date
              {sortField === "sold_on" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("company_name")}>
              Sold To
              {sortField === "company_name" &&
                (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("total_price")}>
              Total Price
              {sortField === "total_price" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => (
            <tr
              key={invoice.invoiceNumber}
              ref={index === invoices.length - 1 ? lastInvoiceElementRef : null}
              onClick={() => handleInvoiceClick(invoice)}
            >
              <td>{invoice.invoiceNumber}</td>
              <td>{invoice.soldOn}</td>
              <td>{invoice.companyName}</td>
              <td>{formatPrice(invoice.totalPrice)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && <MyTry />}
      {error && <div className="error-message">Error: {error}</div>}
    </div>
  );
}
// Helper function to format price
const formatPrice = (price) => {
  const number = parseFloat(price);
  return isNaN(number) ? "N/A" : `$${number.toFixed(2)}`;
};
