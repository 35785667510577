import React from "react";

import "../CSS/manageYourOrdersComponent.css";
const OrderDetail = ({ additionalData }) => {
  console.log("🚀 ~ OrderDetail ~ additionalData:", additionalData)
  return (
    <div className="order-detail-container">
      <div className="additional-data-card">
        <h2>Additional Data</h2>
        {additionalData.orders.map((order, index) => (
          <div key={index} className="order-item">
            <h3>Order {index + 1}</h3>
            <p className="order-amount">Amount Sold: {order.amountSold}</p>
            <p className="order-amount">
              Price per Product: {order.amountPerProduct}
            </p>
            <p className="order-drug-name">Drug Name: {order.drugName}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderDetail;
