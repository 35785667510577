import Axios from "axios";
import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./logInPages.css"; // Uncomment and add styles if necessary
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MyTry from "../../helpers/loading/mytry/myTry";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons from react-icons (if installed)

const Login = () => {
  Axios.defaults.withCredentials = true;

  const [userType, setUserType] = useState("Buyer");
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [loading, setLoading] = useState(false); // State to track loading status
  const [loginStatus, setLoginStatus] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false); // State for terms agreement
  const navigate = useNavigate();

  const schema = yup.object().shape({
    username: yup
      .string()
      .email("Invalid email format")
      .required("User email is required"),
    password: yup
      .string()
      .min(4, "Password must be at least 4 characters")
      .max(20, "Password cannot exceed 20 characters")
      .required("Password is required"),
    terms: yup
      .boolean()
      .oneOf([true], "You must agree to the terms and policies")
      .required("Agreement to terms is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = async (data) => {
    if (!termsAgreed) {
      setLoginStatus("You must agree to the terms and policies.");
      return;
    }

    setLoading(true); // Start loading
    let apiUrl = "";

    if (userType === "Buyer") {
      apiUrl = `${process.env.REACT_APP_API_URL}/pharmacy/login`;
    } else if (userType === "Seller") {
      apiUrl = `${process.env.REACT_APP_API_URL}/wholesale/login`;
    }

    try {
      const response = await Axios.post(apiUrl, data);

      if (response.data.Status === "success") {
        const id = response.data.id;
        console.log(id, "User ID after login");

        if (userType === "Buyer") {
          navigate(`/pharmacy/phhome?id=${id}`);
        } else if (userType === "Seller") {
          navigate(`/wholeSale/wshome?id=${id}`);
        }
      } else {
        setLoginStatus(response.data.message || "Login failed");
      }
    } catch (error) {
      console.error("Error during login API request: ", error);
      setLoginStatus("An error occurred during login. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="loginwraper">
      <form onSubmit={handleSubmit(handleLogin)}>
        <h1>Login</h1>
        <select value={userType} onChange={(e) => setUserType(e.target.value)}>
          <option value="Buyer">Pharmacy</option>
          <option value="Seller">Whole sale</option>
          {/* Add more user types here if needed */}
        </select>
        <input
          type="text"
          placeholder="Username..."
          {...register("username")}
        />
        <p className="loginerror">{errors.username?.message}</p>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"} // Toggle between text and password types
            placeholder="Password..."
            {...register("password")}
          />
          <span
            className="toggle-password"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
            {/* Conditionally render the icons */}
          </span>
        </div>
        <p className="loginerror">{errors.password?.message}</p>
        <div className="checkbox-container">
          <label htmlFor="terms">
            I agree to the
            <a
              href="https://jrterms.wordpress.com/terms-and-policies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              
              terms and policies
            </a>
          </label>
          <input
            className="checkbox"
            type="checkbox"
            id="terms"
            name="terms"
            {...register("terms")}
            checked={termsAgreed}
            onChange={(e) => setTermsAgreed(e.target.checked)}
          />
          <p className="loginerror">{errors.terms?.message}</p>
        </div>
        <button type="submit" className="btn" disabled={loading}>
          {loading ? <MyTry /> : "Login"}
        </button>
        <p>
          <Link to="/signUp">Sign up for a new account</Link>
        </p>
      </form>

      {loginStatus && <h1>{loginStatus}</h1>}
    </div>
  );
};

export default Login;
