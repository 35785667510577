import React, { useState } from "react";
import zoneData from "./components/zoneList";
import woredaData from "./components/woredaList";
import "./CSS/adressInput.css"

const UserLocationSelector = ({ onLocationSelect }) => {
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedWoreda, setSelectedWoreda] = useState("");

  const region = [
    "Amhara",
    "Gambela",
    "Dire Dawa",
    "Harari",
    "Somali",
    "Afar",
    "Tigray",
    "Benishangul Gumuz",
    "Oromia",
    "Sidama",
    "Addis Ababa",
    "Southern Nations Nationalities And People's Region",
    "South West",
  ];

  const handleRegionChange = (e) => {
    const newRegion = e.target.value;
    setSelectedRegion(newRegion);
    setSelectedZone("");
    setSelectedWoreda("");

    // Call the callback with selected values
    onLocationSelect({
      region: newRegion,
      zone: "", // Set the initial value for zone
      woreda: "", // Set the initial value for woreda
    });
  };

  const handleZoneChange = (e) => {
    const newZone = e.target.value;
    setSelectedZone(newZone);
    setSelectedWoreda("");

    // Call the callback with selected values
    onLocationSelect({
      region: selectedRegion,
      zone: newZone,
      woreda: "", // Set the initial value for woreda
    });
  };

  const handleWoredaChange = (e) => {
    const newWoreda = e.target.value;
    setSelectedWoreda(newWoreda);

    // Call the callback with selected values
    onLocationSelect({
      region: selectedRegion,
      zone: selectedZone,
      woreda: newWoreda,
    });
  };

  return (
    <div className="container">
      <h1>Address</h1>
      <div className="chooseSite">
        <label htmlFor="region">Select a Region:</label>
        <select
          id="region"
          onChange={handleRegionChange}
          value={selectedRegion}
        >
          <option value="">Select a Region</option>
          {region.map((region) => (
            <option key={region} value={region}>
              {region}
            </option>
          ))}
        </select>
      </div>

      <div className="chooseSite">
        <label htmlFor="zone">Select a Zone:</label>
        <select id="zone" onChange={handleZoneChange} value={selectedZone}>
          <option value="">Select a Zone</option>
          {zoneData[selectedRegion]?.map((zone) => (
            <option key={zone} value={zone}>
              {zone}
            </option>
          ))}
        </select>
      </div>

      <div className="chooseSite">
        <label htmlFor="woreda">Select a Woreda:</label>
        <select
          id="woreda"
          onChange={handleWoredaChange}
          value={selectedWoreda}
        >
          <option value="">Select a Woreda</option>
          {woredaData[selectedZone]?.map((woreda) => (
            <option key={woreda} value={woreda}>
              {woreda}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default UserLocationSelector;
