// src/InvoicePage.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./CSS/seeAdress.css";
import MyTry from "../../../helpers/loading/mytry/myTry";

const SeeMyAddressPh = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [addressData, setAddressData] = useState(null);

  // console.log("🚀 ~ PrintInvoice ~ data:", data);
  console.log("🚀 ~ PrintInvoice ~ ownId:", id);

  useEffect(() => {
    const fetchAddressData = async () => {
      console.log("REACT_APP_API_URL:", process.env.REACT_APP_API_URL);

      const url = `${process.env.REACT_APP_API_URL}/pharmacy/watchMyAddress?id=${id}`;
      try {
        console.log("🚀 ~ fetchAddressData ~ url:", url);
        const response = await axios.get(url, { withCredentials: true });
        console.log(response.data, "check");
        setAddressData(response.data.data);
      } catch (error) {
        console.error("There was an error fetching the address data!", error);
      }
    };
    fetchAddressData();
  }, [id]);

  if (!addressData) {
    return <MyTry/>;
  }

  return (
    <div className="invoice">
      <p>Region: {addressData.region}</p>
      <p>Zone: {addressData.zone}</p>
      <p>Woreda: {addressData.woreda}</p>
      <p>Kebele: {addressData.kebele}</p>
      <p>House No: {addressData.house_no}</p>
      <p>P.O. Box: {addressData.pobox}</p>
      <p>Street: {addressData.street}</p>
      <p>Latitude: {addressData.latitude}</p>
      <p>Longitude: {addressData.longitude}</p>
      <button
        onClick={() =>
          window.open(
            `/wholeSale/mapPage?latitude=${addressData.latitude}&longitude=${addressData.longitude}`,
            "_blank"
          )
        }
      >
        Open in Map
      </button>
    </div>
  );
};

export default SeeMyAddressPh;
