const zoneData = {
    Amhara: ["South Gondar Zone",
    "North Wollo Zone",
    "South Wollo Zone",
    "North Shewa Zone",
    "East Gojjam Zone",
    "West Gojjam Zone",
    "Waghimra Zone",
    "Awi Zone",
    "Oromiya-Special Zone",
    "Bahir Dar Special Zone",
    "Argoba Special Zone",
    "Dessie Town Administration",
    "Gondar Town Special Zone",
    "North Gondar Zone",
    "Central Gonder",
    "West Gonder"
    ],
    Gambela: ["Itang Special Zone",
    "Anywaa Zone",
    "Nuer Zone",
    "Majang Zone"
    ],
    "Dire Dawa": ["Dire Dawa"
    ],
    Harari: ["Harari"
    ],
    Somali: ["Liben Zone",
    "Afder Zone",
    "Jarar Zone",
    "Nogob Zone",
    "Erer Zone",
    "Shebele Zone",
    "Fafan Zone",
    "Korahe Zone",
    "Sitti Zone",
    "Dollo Zone",
    "Dawa Zone"
    ],
    Afar: ["Awsi Rasu",
    "Kilbet Rasu",
    "Gabi Rasu",
    "Fantena Rasu",
    "Hari Rasu",
    "Semera"
    ],
    Tigray: ["South Zone",
    "South East Zone",
    "Central Zone",
    "Mekele Special Zone",
    "West Zone",
    "East Zone",
    "North West Zone"
    ],
    "Benishangul Gumuz": ["Assosa Zone",
    "Kamashi Zone",
    "Metekel Zone",
    "Mao Ena Komo"
    ],
    Oromia: ["West Arsi",
    "Kelam Welega",
    "Arsi",
    "West Bale",
    "East Bale",
    "Borena",
    "Guji",
    "West Guji",
    "East Hararghe",
    "West Hararghe",
    "Buno Bedele",
    "East Shewa",
    "Ilu Abba Bora",
    "West Shewa",
    "North Shewa",
    "South West Shewa",
    "Oromia Special Zone Surrounding Finfinne",
    "Adama Special Zone",
    "Jimma",
    "West Wollega",
    "East Wollega",
    "Horo Guduru Wollega",
    "Asela Liyu Zone",
    "Bishoftu Liyu Zone",
    "Burayu",
    "Dukam Liyu Zone",
    "Gelan Liyu Zone",
    "Laga Tafo Laga Dadi",
    "Nekemte Liyu Zone",
    "Jimma Liyu",
    "Sabata",
    "Shashamane Liyu Zone",
    "Sululta",
    "Robe Liyu Zone",
    "Ambo City Admin",
    "Batu Special Zone",
    "Modjo Liyu Zone",
    "Holeta Liyu Zone",
    "Woliso Special Zone",
    "Bishan Guracha Liyu Zone"
    ],
    Sidama: ["Hawassa", "Sidama"],
    "Addis Ababa": ["Addis Ketema",
    "Arada",
    "Gullele",
    "Kirkos",
    "Kolfe Keranio",
    "Lideta",
    "Nifas Silk-Lafto",
    "Yeka",
    "Bole",
    "Lemi Kura",
    "Akaki Kality"
    ],
    "Southern Nations Nationalities And People's Region": ["Silti Zone",
    "Gamo Gofa Zone",
    "Bench Maji Zone",
    "South Omo Zone",
    "Bench Sheko Zone",
    "Wolayita Zone",
    "Dawro Zone",
    "Gamo Zone",
    "Gofa Zones",
    "Gedeo Zone",
    "Gurage Zone",
    "Hadiya Zone",
    "Keffa Zone",
    "Kembata Tembaro Zone",
    "Sheka Zone",
    "Independent Districts",
    "Yem",
    "Hawassa City Admnistration",
    "Dilla",
    "Segen Akababi Hizboch",
    "Halaba",
    "Basketo",
    "Konta Liyu",
    "Konso"
    ],
    "South West": ["Dawuro"]
    
  };
  export default zoneData;