import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { ShopContext } from "../../context/shopContext";
import { Products } from "../components/medicines";
import MyTry from "../../../helpers/loading/mytry/myTry";
import "../CSS/shop.css";
import { FaCartPlus } from "react-icons/fa";

const ShopForMedicalSupply = () => {
  const shopContext = useContext(ShopContext);
  const [reRender, setRerender] = useState(true);
  const [loading, setLoading] = useState(true); // Add loading state
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const wholeSaleId = searchParams.get("wholeSaleId");
  const ownId = searchParams.get("ownId");
  const companyName = searchParams.get("WsName");
  const separater = searchParams.get("separater");

  const PRODUCTS = shopContext.getProductedData(); // Retrieve product data

  useEffect(() => {
    setRerender(separater);
  }, [separater, wholeSaleId]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchUrl = `${process.env.REACT_APP_API_URL}/pharmacy/msgetExistingstockFromWholeSale?id=${wholeSaleId}&buyer_id=${ownId}`;
      setLoading(true); // Set loading to true before fetching data
      try {
        const response = await axios.get(fetchUrl, {
          withCredentials: true, // Enable sending cookies with the request
        });

        if (Array.isArray(response.data.data)) {
          const newData = response.data.data;

          if (newData && newData.length > 0) {
            if (shopContext.getProductedData().length > 0) {
              shopContext.setProductData(...newData);
            } else {
              shopContext.setProductData(newData);
            }
          }
        } else {
          console.error("Error: Response data is not an array");
        }
        setLoading(false); // Set loading to false after data is loaded
      } catch (error) {
        console.error("Error:", error);
        setLoading(false); // Set loading to false if there's an error
      }
    };

    if (ownId) {
      fetchData();
    } else {
      console.log("who am i");
    }
  }, [wholeSaleId, ownId, companyName, reRender, shopContext, separater]);

  return (
    <div className="order-page">
      <h1>{companyName} Order Page</h1>

      <Link
        to={`/pharmacy/cartForMS?wholeSaleId=${wholeSaleId}&ownId=${ownId}`}
        className="link-button"
      >
        <FaCartPlus />
        Go to Cart
      </Link>

      <div>
        <h2>Medical supply's</h2>
        {loading ? (
          <MyTry />
        ) : PRODUCTS.length > 0 ? (
          <div className="product-list">
            {PRODUCTS.map((medicine) => (
              <div key={medicine.id} className="product-item">
                <Products data={medicine} />
              </div>
            ))}
          </div>
        ) : (
          <div className="no-products">
            <p>This WholeSale doesn't seem to have products in stock.</p>
            <Link
              to={`/pharmacy/phHomesearchByWholeSale?id=${ownId}`}
              className="link-button"
            >
              <button>Explore Other WholeSales</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopForMedicalSupply;
