import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { FaMapMarkerAlt, FaPhoneAlt, FaHome, FaInbox } from "react-icons/fa"; // Import icons
import "./css/forWsPharmacysProfile.css";
import MyTry from "../../helpers/loading/mytry/myTry"
import FileDownload from "js-file-download";

const FrWsPhProfile = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [data, setData] = useState(null);
  const [datas, setDatas] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tenaBiroNotification, setTenaBiroNotification] = useState(" ");
  const [gibrBiroNotification, setGibeBiroNotification] = useState(" ");
    const [filenameOfTb, setFilenameOfTb] = useState("");
    const [filenameOfGbr, setFilenameOfGbr] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const fetchUrl = `${process.env.REACT_APP_API_URL}/wholesale/getPhProfile?id=${id}`;
      try {
        const response = await axios.get(fetchUrl, { withCredentials: true });
        console.log("🚀 ~ fetchData ~ response:", response);
        setDatas(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

   useEffect(() => {
     if (data) {
       // Update the state inside the useEffect hook

       setFilenameOfTb(data.filenameOfTb);
       setFilenameOfGbr(data.filenameOfGb);
     }
   }, [data]); 

   useEffect(() => {
     const fetchLData = async () => {
       const fetchDataUrl = `${process.env.REACT_APP_API_URL}/pharmacy/profile/licenceName?id=${id}`;
       try {
         // Set isLoading to true when starting to fetch data
         setLoading(true);
         const response = await axios.get(fetchDataUrl, {
           withCredentials: true, // Enable sending cookies with the request
         });
         // Check if the response data is empty
         if (response.data.Status === "unsuccess") {
           console.log("Response is empty");
                    setGibeBiroNotification(
                      "we couldn't locate there license info"
                    );

           // Handle the case where the response is empty
         } else {
           setData(response.data.data);
           console.log(response, "HERE IN THE PROFILE LICENCE PAGE ");
         }
         setLoading(false);
       } catch (error) {
         console.error("Error:", error);
         setError(true);
         setLoading(false);
       } finally {
         // Set isLoading to false regardless of success or error
         setLoading(false);
       }
     };

     if (id) {
       fetchLData();
     } else {
       setLoading(false);
     }
   }, [id]);
  

   const handleViewFile1 = async (e) => {
     console.log(
       "this will be sent to the api bc we need a file with this name",
       filenameOfTb
     );
     e.preventDefault();
     setLoading(true);
     const file1DownloadUrltest = `${process.env.REACT_APP_API_URL}/pharmacy/profile/licencedownload/file1?filenameOfTb=${filenameOfTb}`;

     try {
       const response = await axios.get(file1DownloadUrltest, {
         responseType: "blob",
         withCredentials: true,
       });

       // Check if the response contains the file
       if (response.data) {
         FileDownload(response.data, filenameOfTb);
         setTenaBiroNotification("YOU GOT THE DOWNLOAD SUCCESSFULLY");
         setLoading(false);
       } else {
         console.error("No file data in the response.");
         // Handle this case according to your requirements
       }
     } catch (error) {
       if (error.response && error.response.statusText === 404) {
         setTenaBiroNotification(
           "they don't seem to have a license in the database please contact them using there phone"
         );
       } else {
         setGibeBiroNotification("Error downloading file 2:", error);
         console.log(error);
       }
     } finally {
       // Set isLoading to false after the API response is received
       setLoading(false);
     }
   };
   const handleViewFile2 = async (e) => {
     console.log(
       "this will be sent to the api bc we need a file with this name",
       filenameOfGbr
     );
     e.preventDefault();
     setLoading(true);
     const file2DownloadUrltest = `${process.env.REACT_APP_API_URL}/pharmacy/profile/licencedownload/file2?filenameOfGbr=${filenameOfGbr}`;

     try {
       const response = await axios.get(file2DownloadUrltest, {
         responseType: "blob",
         withCredentials: true,
       });

       // Check if the response contains the file
       if (response.data) {
         FileDownload(response.data, filenameOfGbr);
         setLoading(false);
         setGibeBiroNotification("YOU GOT THE DOWNLOAD SUCCESSFULLY");
       } else {
         console.error("No file data in the response.");
         // Handle this case according to your requirements
       }
     } catch (error) {
       if (error.response && error.response.statusText === 404) {
         setGibeBiroNotification(
           " they don't seem to have a license in the database please contact them using there phone number"
         );
       } else {
         setGibeBiroNotification("Error downloading file 2:", error);
         console.log(error);
       }
     } finally {
       // Set isLoading to false after the API response is received
       setLoading(false);
     }
   };

  if (loading) return <MyTry/>;
  if (error) return <p>Error loading data.</p>;

  const {
    phone_number,
    company_name,
    address: {
      region,
      zone,
      woreda,
      kebele,
      street,
      house_no,
      pobox,
      latitude,
      longitude,
    },
  } = datas;

  return (
    <div className="container">
      <h1>{company_name}</h1>
      <h4>{id}</h4>
      <p>
        <FaPhoneAlt className="icon" /> Phone Number: {phone_number}
      </p>
      <h2>Address</h2>
      <p>
        <FaMapMarkerAlt className="icon" /> Region: {region}
      </p>
      <p>
        <FaMapMarkerAlt className="icon" /> Zone: {zone}
      </p>
      <p>
        <FaMapMarkerAlt className="icon" /> Woreda: {woreda}
      </p>
      <p>
        <FaMapMarkerAlt className="icon" /> Kebele: {kebele}
      </p>
      <p>
        <FaMapMarkerAlt className="icon" /> Street: {street}
      </p>
      <p>
        <FaHome className="icon" /> HouseNo: {house_no}
      </p>
      <p>
        <FaInbox className="icon" /> P.O. Box: {pobox}
      </p>
      <div class="file-group">
        <button class="view-file-button" onClick={handleViewFile1}>
          View tena biro license
        </button>
        <span>{tenaBiroNotification}</span>
        <button class="view-file-button" onClick={handleViewFile2}>
          View Revenue license
        </button>
        <span>{gibrBiroNotification}</span>
      </div>
      <button
        onClick={() =>
          window.open(
            `/wholeSale/mapPage?latitude=${latitude}&longitude=${longitude}`,
            "_blank"
          )
        }
      >
        <FaMapMarkerAlt className="icon" /> Open in Map
      </button>
    </div>
  );
};

export default FrWsPhProfile;
