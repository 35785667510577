import * as yup from "yup";

const addressSchema = yup.object().shape({
  kebele: yup.string().required("Kebele is required"),
  street: yup.string().required("Street name is required"),
  house_no: yup.string().required("House number is required"),
  pobox: yup.string().required("PO Box is required"),
  woreda: yup.string().required("Woreda is required"),
});

export default addressSchema;
