import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaPhone, FaBookMedical, FaRegFilePdf } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import {
  TbReceiptTax,
  TbLogout,
  // TbPasswordUser,
  TbBuildingBank,
} from "react-icons/tb";
import { TiArrowBack } from "react-icons/ti";
import "./css/wholesaleProfile.css";
import MyTry from "../../helpers/loading/mytry/myTry";
import { CiSettings } from "react-icons/ci";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <p>Oops! Something went wrong.</p>; // Render a fallback UI
    }

    return this.props.children;
  }
}

const WsProfilePage = () => {
  const [isEditing, setEditing] = useState(false);
  const [companyName, setCompanyName] = useState("Your Company Name");
  const [phoneNumber1, setPhoneNumber1] = useState("091-234-5678");
  const [phoneNumber2, setPhoneNumber2] = useState("091-234-5678");
  const [phoneNumber3, setPhoneNumber3] = useState("091-234-5678");
  const [licenseNo, setLicenseNo] = useState("Your License Number");
  const [tinNo, setTinNo] = useState("Your Tin Number");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
   const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      // Update the state inside the useEffect hook
      setCompanyName(data.companyName);
      setPhoneNumber1(data.phoneNumber1);
      setPhoneNumber2(data.phoneNumber2);
      setPhoneNumber3(data.phoneNumber3);
      setLicenseNo(data.licenceNumber);
      setTinNo(data.tinNumber);
    }
  }, [data]); // This will update the state when 'data' changes

  const handleSetting = () => {
    navigate(`/wholeSale/wsdeleteAccount?id=${id}`);
  };


  useEffect(() => {
    const fetchData = async () => {
      const fetchUrl = `${process.env.REACT_APP_API_URL}/wholesale/profile?id=${id}`;
      try {
        const response = await axios.get(fetchUrl, {
          withCredentials: true, // Enable sending cookies with the request
        });
        setData(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setError(true);
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const handleEdit = () => {
    setEditing(!isEditing);
  };

  const handleApplyChanges = async () => {
    try {
      const submitUrl = `${process.env.REACT_APP_API_URL}/wholesale/profile/userInfoupdate`;
      const response = await axios.put(
        submitUrl,
        {
          CompanyName: companyName,
          PhoneNumber1: phoneNumber1,
          PhoneNumber2: phoneNumber2,
          PhoneNumber3: phoneNumber3,
          LicenseNo: licenseNo,
          TinNo: tinNo,
          userId: id,
        },
        {
          withCredentials: true,
        }
      );

      if (response.data.Status === "Success") {
        console.log("Profile updated successfully");
      } else {
        setError("Unable to update profile");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Reset isEditing to false regardless of success or error
      setEditing(false);
    }
  };

  const logout = async () => {
    const leaveUrl = `${process.env.REACT_APP_API_URL}/logout`;

    try {
      const response = await axios.post(
        leaveUrl,
        {},
        {
          withCredentials: true, // Enable sending cookies with the request
        }
      );
      console.log("🚀 ~ logout ~ response:", response);

      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
      // Handle error, maybe show a message to the user
    }
  };

  if (error) {
    return (
      <div className="error">
        <h1>Error while fetching the profile</h1>
        <Link to="/wholeSale/wshome">
          <h2>
            <TiArrowBack />
            go back to the Home
          </h2>
        </Link>
      </div>
    );
  }

  if (isLoading) {
    return <MyTry/>;
  }

  if (!data) {
    return (
      <div className="error">
        <h1> Couldn't fetch user information </h1>
        <Link to="/login">
          <h2>Try logging in again</h2>
        </Link>
      </div>
    );
  }

  return (
    <div className="profile-page">
      <h1>Profile Page</h1>
      <button className="go-back-btn" onClick={handleSetting}>
        <CiSettings />
        Setting
      </button>
      <ErrorBoundary>
        <div className="profile-info">
          <div className="info-row">
            <label> Company Name: </label> <h4>{id}</h4>
            {isEditing ? (
              <input
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className="profile-input"
              />
            ) : (
              <span>{companyName}</span>
            )}
          </div>
          <div className="info-row">
            <label>
              <FaPhone /> Phone Number1:
            </label>
            {isEditing ? (
              <input
                type="text"
                value={phoneNumber1}
                onChange={(e) => setPhoneNumber1(e.target.value)}
                className="profile-input"
              />
            ) : (
              <span>{phoneNumber1}</span>
            )}
          </div>
          <div className="info-row">
            <label>
              <FaPhone />
              Phone Number2:
            </label>
            {isEditing ? (
              <input
                type="text"
                value={phoneNumber2}
                onChange={(e) => setPhoneNumber2(e.target.value)}
                className="profile-input"
              />
            ) : (
              <span>{phoneNumber2}</span>
            )}
          </div>
          <div className="info-row">
            <label>
              <FaPhone />
              Phone Number3:
            </label>
            {isEditing ? (
              <input
                type="text"
                value={phoneNumber3}
                onChange={(e) => setPhoneNumber3(e.target.value)}
                className="profile-input"
              />
            ) : (
              <span>{phoneNumber3}</span>
            )}
          </div>
          <div className="info-row">
            <label>
              <FaBookMedical />
              License No:
            </label>
            {isEditing ? (
              <input
                type="text"
                value={licenseNo}
                onChange={(e) => setLicenseNo(e.target.value)}
                className="profile-input"
              />
            ) : (
              <span>{licenseNo}</span>
            )}
          </div>
          <div className="info-row">
            <label>
              <TbReceiptTax />
              Tin No:
            </label>
            {isEditing ? (
              <input
                type="text"
                value={tinNo}
                onChange={(e) => setTinNo(e.target.value)}
                className="profile-input"
              />
            ) : (
              <span>{tinNo}</span>
            )}
          </div>
        </div>

        <div className="actions">
          <button className="edit-btn" onClick={handleEdit}>
            {isEditing ? "Cancel" : "Edit Profile"}
          </button>
          {isEditing && (
            <button className="apply-btn" onClick={handleApplyChanges}>
              Apply Changes
            </button>
          )}
          <div className="button-group">
            <Link to={`/wholeSale/wsProfileChangelicence?id=${id}`}>
              <button className="profile-action-btn">
                <FaRegFilePdf />
                Check/Change License Picture
              </button>
            </Link>
            <Link to={`/wholesale/wsProfileChangelocation?id=${id}`}>
              <button className="profile-action-btn">
                <FaLocationDot /> Check/Change location or Adress
              </button>
            </Link>
            {/* <Link to={`/wholeSale/wsProfileChangelicence?id=${id}`}>
              <button className="profile-action-btn">
                
                <TbPasswordUser />
                Check/Change user name and password
              </button>
            </Link> */}
            <Link to={`/wholeSale/wsProfileChangeBankDetail?id=${id}`}>
              <button className="profile-action-btn">
                <TbBuildingBank /> add or remove bank accounts
              </button>
            </Link>

            <button onClick={() => logout()}>
              <TbLogout />
              Logout
            </button>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default WsProfilePage;
