import React, { useState } from "react";

const CoordinateInput = ({ onCoordinatesUpdate }) => {
  const [coordinates, setCoordinates] = useState("");

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const coordinatesStr = `${latitude}, ${longitude}`;
          setCoordinates(coordinatesStr);
          onCoordinatesUpdate(latitude, longitude); // Pass coordinates to the parent component
        },
        function (error) {
          console.error("Error getting location:", error);
          alert(
            "Error getting location. Please check your settings and try again."
          );
        }
      );
    } else {
      alert("Geolocation is not supported in your browser.");
    }
  };

  return (
    <div>
      <input
        type="text"
        value={coordinates}
        placeholder="Latitude, Longitude"
        readOnly
      />
      <button type="button" onClick={getLocation}>
        Refresh Location
      </button>
    </div>
  );
};

export default CoordinateInput;
