import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { FcPrint } from "react-icons/fc";
import ReactToPrint from "react-to-print";
import { ToWords } from "to-words";
import "./InvoicePage.css";
import logo from "../helpers/logo/Untitled.svg";
import MyTry from "../helpers/loading/mytry/myTry";

const MsPrintInvoice = () => {
  const location = useLocation();
  const printRef = React.useRef();

  // Memoize the data to avoid unnecessary re-renders
  const data = useMemo(() => location.state?.data || [], [location.state]);

  const searchParams = new URLSearchParams(location.search);
  const ownId = searchParams.get("ownId");

  const [invoiceData, setInvoiceData] = useState(null);

  useEffect(() => {
    const fetchInvoiceData = async () => {
      const fetchUrl = `${process.env.REACT_APP_API_URL}/wholeSale/mscreateAnInvoicePage`;
      try {
        const response = await axios.get(fetchUrl, {
          params: {
            id: ownId,
            invoiceNumber: data,
          },
          withCredentials: true,
        });
        setInvoiceData(response.data.data);
      } catch (error) {
        console.error("There was an error fetching the invoice data!", error);
      }
    };
    fetchInvoiceData();
  }, [data, ownId]);

  if (!invoiceData) {
    return <MyTry/>;
  }

  const {
    shopInfo = {},
    saleInfo = {},
    customerInfo = {},
    products = [],
    totals = {},
  } = invoiceData;

  // Initialize toWords function
  const convertToWords = new ToWords();

  // Convert the grand total to words
  const totalInWords = convertToWords.convert(totals.grandTotal);

  return (
    <div className="invoice-page">
      <div className="header">
        <span className="logoparent">
          <img className="logo" src={logo} alt="" />
        </span>
        <ReactToPrint
          trigger={() => (
            <button className="print-button">
              <FcPrint />
            </button>
          )}
          content={() => printRef.current}
        />
      </div>

      <div className="invoice-container" ref={printRef}>
        <div className="header-content">
          
          <div className="shop-info">
            <h1>{shopInfo.shopName}</h1>
            <p>Sub City: {shopInfo.subCity}</p>
            <p>Kebele: {shopInfo.kebele}</p>
            <p>Building: {shopInfo.building}</p>
            <p>
              Phone: {shopInfo.phone1}, {shopInfo.phone2}
            </p>
            <p>Fax: {shopInfo.fax}</p>
            <p>TIN: {shopInfo.tin}</p>
            <p>VAT Reg. No: {shopInfo.vatRegNo}</p>
          </div>

          <div className="info-sections">
            <div className="sales-info">
              <h2>Sales Information</h2>
              <p>Date: {saleInfo.date}</p>
              <p>FS No: {saleInfo.fsNo}</p>
              <p>Invoice No: {saleInfo.invoiceNo}</p>
            </div>

            <div className="customer-info">
              <h2>Customer Information</h2>
              <p>
                Customer Name:
                <Link to={`/wholeSale/phProfile?id=${customerInfo.phId}`}>
                  {customerInfo.customerName}
                </Link>
              </p>
              <p>Address: {customerInfo.address}</p>
              <p>TIN: {customerInfo.tin}</p>
            </div>
          </div>
        </div>

        <div className="products-table">
          <h2>Products</h2>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Description</th>
                <th>Code</th>
                <th>Batch No</th>
                <th>Expiry Date</th>
                <th>UOM</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{product.description}</td>
                  <td>{product.code}</td>
                  <td>{product.batchNo}</td>
                  <td>{product.expiryDate}</td>
                  <td>{product.uom}</td>
                  <td>{product.quantity}</td>
                  <td>{product.unitPrice}</td>
                  <td>{product.totalPrice}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="8">Sub Total</td>
                <td>{totals.subTotal}</td>
              </tr>
              <tr>
                <td colSpan="8">Non Tax</td>
                <td>{totals.nonTax}</td>
              </tr>
              <tr>
                <td colSpan="8">Grand Total</td>
                <td>{totals.grandTotal}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="total-in-words">
          <h2>Words:</h2>
          <p>{totalInWords}</p>
        </div>
        <div className="reciverInput">
          I receved the above goods/services in good condition:
          _________________________
        </div>
        <div className="invoiceFooter">
          <h3 className="header">Memo / Note</h3>
          <div className="breakLine" />
          <div className="footerBody">
            <h4 className="detailHead">Prepared By: __________________</h4>
            <h4 className="detailHead"> </h4>
            <h4 className="detail">Aproved By: __________________</h4>
            <h4 className="detail">Signature: __________________</h4>
            <h4 className="detail">Checked By: __________________</h4>
            <h4 className="detail">Recived By: __________________</h4>
          </div>
          <div className="breakLine" />
          <div className="closer">
            <h4>
              <b>
                This receipt is not valid unless the fiscal receipt is attached!
              </b>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsPrintInvoice;
