import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./css/bankForWs.css";
// Import logos
import abayLogo from "../../../../helpers/bankLogos/AbayBank.webp";
import addisInternationalLogo from "../../../../helpers/bankLogos/adis-international_n-128x128.png";
import ahaduLogo from "../../../../helpers/bankLogos/ahadu-logo-128x128.jpeg";
import BankofAbyssinia from "../../../../helpers/bankLogos/abyssinia-bank-logo-profit-financial-statement-shareholder-e1685430860462-128x100.jpg";
import AmharaBank from "../../../../helpers/bankLogos/Amhara-bank-to-start-operation-sene-11-2014-2-128x128.jpg";
import AwashBank from "../../../../helpers/bankLogos/awash-bank-1.webp";
import BerhanBank from "../../../../helpers/bankLogos/berhan-bank.jpg";
import BunaBank from "../../../../helpers/bankLogos/bunna-bank-logo-128x128.webp";
import Cbe from "../../../../helpers/bankLogos/cbe-1-128x128.png";
import Coop from "../../../../helpers/bankLogos/coop-128x128.jpg";
import DashenBank from "../../../../helpers/bankLogos/dashen-bank-128x128.png";
import EnatBank from "../../../../helpers/bankLogos/enat-bank-128x128.png";
import GadaaBank from "../../../../helpers/bankLogos/gada-bank-small-128x128.webp";
import GlobalBank from "../../../../helpers/bankLogos/global-bank-128x128.webp";
import HibretBank from "../../../../helpers/bankLogos/hibret-Bank-128x128.png";
import HijraBank from "../../../../helpers/bankLogos/hijra-bank-128x128.webp";
import LionBank from "../../../../helpers/bankLogos/lion-bank-o-128x128.jpg";
import NibBank from "../../../../helpers/bankLogos/nib-bank-1-128x128.jpg";
import OromiaBank from "../../../../helpers/bankLogos/OROMIABANK-LOGO-1-128x128.webp";
import ShebelleBank from "../../../../helpers/bankLogos/shabelle-bank-1200x630wa-128x128.webp";
import SiinqeeBank from "../../../../helpers/bankLogos/siinquee-bankfor-pc-128x128.webp";
import TsedeyBank from "../../../../helpers/bankLogos/tsedey-bank2-128x128.webp";
import TsehayBank from "../../../../helpers/bankLogos/tsehay-bank4-128x128.webp";
import WegagenBank from "../../../../helpers/bankLogos/wegagen-bank-logo-300x233-1-128x128.png";
import ZamzamBank from "../../../../helpers/bankLogos/zamzam-bank-logo-1-128x128.jpg";
import ZemenBank from "../../../../helpers/bankLogos/zemen-bank-128x128.webp";
import MyTry from "../../../../helpers/loading/mytry/myTry";
// Add more logo imports here...

const logoMap = {
  Abay_Bank: abayLogo,
  Addis_International_Bank: addisInternationalLogo,
  Ahadu_Bank: ahaduLogo,
  Amhara_Bank: AmharaBank,
  Bank_of_Abyssinia: BankofAbyssinia,
  Awash_Bank: AwashBank,
  Berhan_Bank: BerhanBank,
  Bunna_Bank: BunaBank,
  Commercial_Bank_of_Ethiopia_CBE: Cbe,
  Cooperative_Bank_of_Oromia: Coop,
  Dashen_Bank: DashenBank,
  Enat_Bank: EnatBank,
  Gadaa_Bank: GadaaBank,
  Global_Bank_Ethiopia: GlobalBank,
  Hibret_Bank: HibretBank,
  Hijra_Bank: HijraBank,
  Lion_Bank: LionBank,
  Nib_Bank: NibBank,
  Oromia_Bank: OromiaBank,
  Shabelle_Bank: ShebelleBank,
  Siinqee_Bank: SiinqeeBank,
  Tsedey_Bank: TsedeyBank,
  Tsehay_Bank: TsehayBank,
  Wegagen_Bank: WegagenBank,
  ZamZam_Bank: ZamzamBank,
  Zemen_Bank: ZemenBank,
  // Map other bank names to their logos
};

const BankForm = () => {
  const [banks, setBanks] = useState({
    Abay_Bank: "",
    Addis_International_Bank: "",
    Ahadu_Bank: "",
    Amhara_Bank: "",
    Awash_Bank: "",
    Bank_of_Abyssinia: "",
    Berhan_Bank: "",
    Bunna_Bank: "",
    Commercial_Bank_of_Ethiopia_CBE: "",
    Cooperative_Bank_of_Oromia: "",
    Dashen_Bank: "",
    Enat_Bank: "",
    Global_Bank_Ethiopia: "",
    Gadaa_Bank: "",
    Hibret_Bank: "",
    Lion_Bank: "",
    Hijra_Bank: "",
    Oromia_Bank: "",
    Nib_Bank: "",
    Siinqee_Bank: "",
    Shabelle_Bank: "",
    Tsehay_Bank: "",
    Tsedey_Bank: "",
    ZamZam_Bank: "",
    Wegagen_Bank: "",
    Zemen_Bank: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isNew, setIsNew] = useState(false);

  const location = useLocation();

  const id = new URLSearchParams(location.search).get("id");
  const [bankId, setBankId] = useState(null);

  useEffect(() => {
    if (!id) {
      setIsNew(true);
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      const fetchUrl = `${process.env.REACT_APP_API_URL}/wholesale/manageBanks?id=${id}`;
      try {
        console.log("🚀 ~ fetchData ~ fetchUrl:", fetchUrl);
        const response = await axios.get(fetchUrl, { withCredentials: true });
        console.log("🚀 ~ fetchData ~ response:", response);
        if (response.data.data.length === 0) {
          console.log("🚀 ~ fetchData ~ 0response:", response);

          setIsNew(true);
        } else {
          const bankData = response.data.data[0];
          setBanks(bankData);
          setBankId(bankData.id);
        }
        console.log("🚀 ~ fetchData ~ responses:", response);
        setLoading(false);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          // Specific handling for 404 error
          setIsNew(true); // Handle as new entry if 404 is due to no data
        } else {
          // Handle other errors
          setError(err.message); // Set error message if an exception occurs
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBanks((prevBanks) => ({
      ...prevBanks,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const updateUrl = `${process.env.REACT_APP_API_URL}/wholesale/manageBanks?id=${bankId}`;
      const submitUrl = `${process.env.REACT_APP_API_URL}/wholesale/manageBanks`;

      if (isNew) {
        await axios.post(
          submitUrl,
          { user_id: id, ...banks },
          { withCredentials: true }
        );
      } else {
        await axios.put(updateUrl, banks, { withCredentials: true });
      }
      window.location.reload();
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <MyTry />;
  if (error) return <p className="error-message">Error: {error}</p>;

  return (
    <form className="bank-form" onSubmit={handleSubmit}>
      <h1>{isNew ? "Add New Bank Details" : "Update Bank Details"}</h1>
      {Object.keys(banks).map((bankKey) => {
        if (bankKey === "id" || bankKey === "user_id") return null; // Exclude id and user_id
        return (
          <div className="bank-entry" key={bankKey}>
            {logoMap[bankKey] && (
              <div className="bank-logo">
                <img src={logoMap[bankKey]} alt={`${bankKey} logo`} />
              </div>
            )}
            <label>
              {bankKey.replace(/_/g, " ")}
              <input
                type="text"
                name={bankKey}
                value={banks[bankKey]}
                onChange={handleChange}
                placeholder={`Enter account number for ${bankKey}`}
              />
            </label>
          </div>
        );
      })}
      <button type="submit" className="submit-button">
        {isNew ? "Add Bank Details" : "Update Bank Details"}
      </button>
    </form>
  );
};

export default BankForm;
