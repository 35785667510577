import { useEffect, useState } from "react";
import axios from "axios";

export default function searchByForWs(
  query,
  sortField,
  sortOrder,
  pageNumber,
  ownId
) {
  console.log("🚀 ~ SearchByDate ~ pageNumber:", pageNumber);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error, setError] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [invoices, setInvoices] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [hasMore, setHasMore] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setInvoices([]);
    console.log("🚀 ~ sortOrder:", sortOrder);
    console.log("🚀 ~ sortField:", sortField);
  }, [query, sortField, sortOrder, ownId]);
  console.log("🚀 ~ sortOrder:", sortOrder);
  console.log("🚀 ~ sortField:", sortField);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      // url: "http://openlibrary.org/search.json",

      url: `${process.env.REACT_APP_API_URL}/wholeSale/invoices?id=${ownId}`,
      params: {
        q: query,
        page: pageNumber,
        sortField: sortField,
        sortOrder: sortOrder,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      withCredentials: true,
    })
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);

        setInvoices((previnvoices) => {
          return [
            ...new Set([
              ...previnvoices,
              ...res.data.data.map((b) => ({
                invoiceNumber: b.invoiceNumber,
                productsId: b.productsId,
                totalPrice: b.totalPrice,
                soldOn: b.soldOn,
                companyName: b.companyName,
                companyId: b.companyId,
              })),
            ]),
          ];
        });
        setHasMore(res.data.data.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [query, pageNumber, ownId, sortOrder, sortField]);
  return { loading, error, invoices, hasMore };
}
