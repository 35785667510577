import React, { useContext } from "react";
import { ShopContext } from "../context/shopContext";
import "./CSS/cartCard.css";

export const MsCartItem = (props) => {
  const { id, productName, price } = props.data;
  console.log("🚀 ~ MsCartItem ~ price:", price)
  console.log("🚀 ~ MsCartItem ~ productName:", productName)
  console.log("🚀 ~ CartItem ~ id:", id);
  const { cartItems, addToCart, removeFromCart, updateCartItemCount } =
    useContext(ShopContext);

  return (
    <div className="cartitem">
      <div className="discription">
        <p>
          <b>{productName}</b>
        </p>
      </div>
      <p>${price}</p>
      <div className="countHandler">
        <button onClick={() => removeFromCart(id)}>-</button>
        <input
          type="number"
          value={cartItems[id]}
          onChange={(e) => updateCartItemCount(Number(e.target.value), id)}
        />
        <button onClick={() => addToCart(id)}>+</button>
      </div>
    </div>
  );
};
