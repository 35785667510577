import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FirstPage from "./firstpage";
import App2 from "./signin/signinform/myFillInPageForNewSignIN/appForLginPage";
import Login from "./login/login";

import PhHomePage from "../folders/homePages/homePagesForPH";
import WsHomePage from "../folders/homePages/homePagesForWS";
import ThesigninpageAdressph from "./signin/thesigninpage3";
import ThesigninpageAdressws from "./signinws/thesigninpage3";
import ThesigninpageUploadph from "./signin/theSignInPage2";
import ThesigninpageUploadws from "./signinws/theSignInPage2";
import PhProfilePage from "../folders/profiles/pharmacysProfile";
import WsProfilePage from "../folders/profiles/wholeSalesProfile";
import NotFound from "../notFound";
import PhProfileLicencePage from "../folders/profiles/components/phchangelicense.jsx";
import WsProfileLicencePage from "../folders/profiles/components/wschangelicense.jsx";

import SearchByGeneral from "../folders/searchPages/searchByGeneral.jsx";

import MedicineForm from "../createABatch/createBach.jsx";
import MedicineFormAdd from "../createABatch/addNewToTheStock.jsx";

import PhNavbar from "../navBars/pharmacysnav.jsx";
import WsNavbar from "../navBars/wholeSalesNav.jsx";
import Shop from "../orderFolder/shop/shop.jsx";
import Cart from "../orderFolder/cart/cart.jsx";
import { ShopContextProvider } from "../orderFolder/context/shopContext.jsx";
import OrderPageManager from "../orderFolder/manageYourOrders.jsx";
import ForPHWholeSalesProfile from "../folders/profiles/forPHHoleSalesProfile.jsx";
import OrderNotification from "../orderFolder/orderpagenotificationFWS.jsx";
import PrintInvoice from "../InvoicesPrints/printsFHS.jsx";
import SearchByDrugName from "../folders/searchPages/searchByDrugName.jsx";
import { IDProvider } from "../helpers/contextProvider/IDContext.js";
import InvoiceSearchByGeneralWs from "../folders/invoices/invoicesForHS.jsx";
import InvoiceSearchByGeneralPh from "../folders/invoices/invoicesForPH.jsx";
import MapPage from "../helpers/map/MapPage.js";
import FrWsPhProfile from "../folders/profiles/forHSPharmacysprofile.jsx";
import UpdateAdressPh from "../folders/profiles/components/phchangeadress.jsx";
import UpdateAdressWs from "../folders/profiles/components/wschangeadress.jsx";
import SeeMyAddressPh from "../folders/profiles/components/seeAdressPh.jsx";
import SeeMyAddressWs from "../folders/profiles/components/seeAdressWs.jsx";
import ListFavorites from "../folders/favoritesPage/displayFavorites.jsx";
import BankForm from "../folders/profiles/components/bank/bankForWs.jsx";
import BankDisplay from "../folders/profiles/components/bank/bankAccountsforWS.jsx";
import MedicinesSearchAndHistory from "../folders/medicineHistory/medicinesHistory.jsx";
import ProductBinCard from "../folders/medicineHistory/displayBinCard.jsx";
import MedicalSupplyForm from "../createABatch/createMedicalSupply/wsmedicalsupplyInput.jsx";
import MedicalSupplyStockDisplay from "../createABatch/createMedicalSupply/mseditExistingStock.jsx";
import ShopPage from "../orderFolder/chooseWhatToShop.jsx";
import MsCart from "../orderFolder/orderForMedicalSupply/cart/cart.jsx";
import ShopForMedicalSupply from "../orderFolder/orderForMedicalSupply/shop/shop.jsx";
import MsOrderPageManager from "../orderFolder/orderForMedicalSupply/manageYourOrders.jsx";
import MsInvoiceSearchByGeneralWs from "../folders/invoices/msinvoices/invoicesForHS.jsx";
import MsInvoiceSearchByGeneralPh from "../folders/invoices/msinvoices/invoicesForPH.jsx";
import MsOrderNotificationMs from "../orderFolder/orderForMedicalSupply/orderpagenotificationFWS.jsx";
import MsPrintInvoice from "../InvoicesPrints/msprintsFHS.jsx";
import WsThesigninpage from "./signin/wsSignInPage/theSignInPage.jsx";
import PrivacyAndPolicy from "../helpers/privacyPolice/privacyPolicy.jsx";
import AccountDeletionPage from "../folders/deleteAccount/playConsoleLink.jsx";
import DeleteAccountPage from "../folders/profiles/components/deleteAccount.jsx";
import WsDeleteAccountPage from "../folders/profiles/components/wsdeleteAccount.jsx";

export default function App() {
  return (
    <div className="App">
      <Router>
        <IDProvider>
          <ShopContextProvider>
            <Routes>
              <Route exact path="/" element={<FirstPage />}></Route>
              <Route
                exact
                path="/privacyPolicy"
                element={<PrivacyAndPolicy />}
              ></Route>
              <Route
                exact
                path="/AccountDelete"
                element={<AccountDeletionPage />}
              ></Route>
              <Route exact path="/signUp" element={<App2 />}></Route>
              <Route exact path="/login" element={<Login />}></Route>
              <Route exact path="invoicePrint" element={<PrintInvoice />} />
              <Route exact path="msinvoicePrint" element={<MsPrintInvoice />} />
              {/* Nested Route */}
              <Route path="pharmacy" element={<PhNavbar />}>
                <Route
                  exact
                  path="phSignUpadress"
                  element={<ThesigninpageAdressph />}
                />
                <Route
                  exact
                  path="signUpUploadph"
                  element={<ThesigninpageUploadph />}
                />
                <Route exact path="phProfile" element={<PhProfilePage />} />
                <Route
                  exact
                  path="phProfileChangelicence"
                  element={<PhProfileLicencePage />}
                />
                <Route exact path="phAdressView" element={<SeeMyAddressPh />} />
                <Route
                  exact
                  path="phProfileChangelocation"
                  element={<UpdateAdressPh />}
                />
                <Route
                  exact
                  path="deleteAccount"
                  element={<DeleteAccountPage />}
                />
                <Route
                  exact
                  path="phHomesearchByWholeSale"
                  element={<SearchByGeneral />}
                />
                <Route
                  exact
                  path="displayWholesales"
                  element={<ListFavorites />}
                />
                <Route
                  exact
                  path="search-by-drug"
                  element={<SearchByDrugName />}
                />
                <Route exact path="shopForMedicine" element={<Shop />} />
                <Route
                  exact
                  path="shopForMedicalSupply"
                  element={<ShopForMedicalSupply />}
                />
                <Route exact path="shopFromWholeSale" element={<ShopPage />} />

                <Route exact path="cart" element={<Cart />} />
                <Route exact path="cartForMS" element={<MsCart />} />
                <Route
                  exact
                  path="OrderPageManager"
                  element={<OrderPageManager />}
                />
                <Route
                  exact
                  path="MsOrderPageManager"
                  element={<MsOrderPageManager />}
                />
                <Route
                  exact
                  path="invoices"
                  element={<InvoiceSearchByGeneralPh />}
                />
                <Route
                  exact
                  path="msinvoices"
                  element={<MsInvoiceSearchByGeneralPh />}
                />
                <Route
                  exact
                  path="forPHWholeSalesProfile"
                  element={<ForPHWholeSalesProfile />}
                />
                <Route
                  exact
                  path="favoritedWholeSale"
                  element={<PhProfileLicencePage />}
                />
                <Route
                  exact
                  path="wsProfileBankDetail"
                  element={<BankDisplay />}
                />
                <Route exact path="phhome" element={<PhHomePage />} />
              </Route>
              <Route path="wholeSale" element={<WsNavbar />}>
                <Route
                  exact
                  path="wsSignUpadress"
                  element={<ThesigninpageAdressws />}
                />
                <Route exact path="mapPage" element={<MapPage />} />
                <Route
                  exact
                  path="signUpUploadws"
                  element={<ThesigninpageUploadws />}
                />
                <Route exact path="signUpBuyer" element={<WsThesigninpage />} />
                <Route exact path="wshome" element={<WsHomePage />} />
                <Route exact path="wsProfile" element={<WsProfilePage />} />
                <Route exact path="phProfile" element={<FrWsPhProfile />} />
                <Route
                  exact
                  path="wsdeleteAccount"
                  element={<WsDeleteAccountPage />}
                />
                <Route exact path="wsAddToStock" element={<MedicineForm />} />
                <Route
                  exact
                  path="wsMSAddToStock"
                  element={<MedicalSupplyForm />}
                />
                <Route
                  exact
                  path="addMedToStock"
                  element={<MedicineFormAdd />}
                />
                <Route
                  exact
                  path="displayMedicalSupply"
                  element={<MedicalSupplyStockDisplay />}
                />
                <Route
                  exact
                  path="medHistory"
                  element={<MedicinesSearchAndHistory />}
                />
                <Route
                  exact
                  path="productsBinCard"
                  element={<ProductBinCard />}
                />
                <Route exact path="orders" element={<OrderNotification />} />
                <Route
                  exact
                  path="msorders"
                  element={<MsOrderNotificationMs />}
                />
                <Route
                  exact
                  path="invoices"
                  element={<InvoiceSearchByGeneralWs />}
                />
                <Route
                  exact
                  path="msinvoices"
                  element={<MsInvoiceSearchByGeneralWs />}
                />
                <Route exact path="wsAdressView" element={<SeeMyAddressWs />} />
                <Route
                  exact
                  path="wsProfileChangelocation"
                  element={<UpdateAdressWs />}
                />
                <Route
                  exact
                  path="wsProfileChangelicence"
                  element={<WsProfileLicencePage />}
                />
                <Route
                  exact
                  path="wsProfileChangeBankDetail"
                  element={<BankForm />}
                />
              </Route>
              {/* 404 - Not Found */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ShopContextProvider>
        </IDProvider>
      </Router>
    </div>
  );
}
