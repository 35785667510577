import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom"; // Assuming you're using React Router

const WsDeleteAccountPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("id");
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); // Hook to navigate between pages

  const handleDeleteRequest = async () => {
    if (
      !window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      )
    ) {
      return;
    }

    setIsDeleting(true);
    setMessage("");

    try {
      const url = `${process.env.REACT_APP_API_URL}/wholesale/deleteAccount`;

      const response = await axios.post(
        url,
        { userId },
        {
          withCredentials: true,
        }
      );

      if (response.data.Status === "Success") {
        setMessage("Your account has been successfully deleted.");
        setTimeout(() => {
          navigate("/login"); // Navigate to the login page after showing the message
        }, 2000); // 2-second delay to let the user see the success message
      } else {
        setMessage(
          "There was an issue deleting your account. Please try again later."
        );
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      setMessage(
        "An error occurred while processing your request. Please contact support."
      );
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div style={styles.container}>
      <h1>Delete Your Account</h1>
      <p>
        Deleting your account is permanent and cannot be undone. Your profile,
        preferences, and order history will be deleted. However, transaction
        history will be retained for legal compliance.
      </p>
      <button
        onClick={handleDeleteRequest}
        style={styles.deleteButton}
        disabled={isDeleting}
      >
        {isDeleting ? "Deleting..." : "Delete My Account"}
      </button>
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "rgba(173, 216, 230, 0.7)", // Light blue with transparency
    padding: "20px",
    borderRadius: "8px",
    fontFamily: "Arial, sans-serif",
    maxWidth: "600px",
    margin: "0 auto",
    color: "black",
  },
  deleteButton: {
    backgroundColor: "red",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    fontSize: "16px",
    cursor: "pointer",
    marginTop: "10px",
  },
  message: {
    marginTop: "20px",
    fontSize: "14px",
    color: "green",
  },
};

export default WsDeleteAccountPage;
