import React, { useContext, useState } from "react";
import { ShopContext } from "../context/shopContext";
import "../CSS/medicines.css";
export const Products = (props) => {
  console.log("🚀 ~ Products ~ props:", props);
  const {
    id,
    productName,
    price,
    batchNum,
    brandName,
    description,
    dosage,
    dose,
    expDate,
    unitOfIssue,
  } = props.data;
  const { addToCart, cartItems } = useContext(ShopContext);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCardClick = () => {
    setIsExpanded(!isExpanded);
  };

  const cartItemAmount = cartItems[id];
  return (
    <div
      className={`product ${isExpanded ? "expanded" : ""}`}
      onClick={handleCardClick}
    >
      <div className="discription">
        <p>
          <b>{productName}</b>
        </p>
        <p>${price}</p>
      </div>
      <div className="addtocartbtn">
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevents the click event from toggling expansion
            addToCart(id);
          }}
        >
          Add to cart{cartItemAmount > 0 && <b> ({cartItemAmount})</b>}
        </button>
      </div>
      {isExpanded && (
        <div className="details">
          <p>
            <b>Batch Number:</b> {batchNum}
          </p>
          <p>
            <b>Brand Name:</b> {brandName}
          </p>
          <p>
            <b>Description:</b> {description}
          </p>
          <p>
            <b>Dosage:</b> {dosage}
          </p>
          <p>
            <b>Dose:</b> {dose}
          </p>
          <p>
            <b>Expiration Date:</b> {expDate}
          </p>
          <p>
            <b>Unit Of Issue:</b> {unitOfIssue}
          </p>
        </div>
      )}
    </div>
  );
};
