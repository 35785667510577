import React, { useContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";
// import "./pharmacyNav.css";
import { IDContext } from "../helpers/contextProvider/IDContext";
import { FaFileInvoice, FaHome } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import test2 from "../helpers/logo/Untitled1.svg";

const WsNavbar = ({ children }) => {
  const { id } = useContext(IDContext);
    const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="Navbar">
        <span className="nav-logo">
          <img className="logo" src={test2} alt="" />
        </span>
        <div className={`nav-items ${isOpen && "open"}`}>
          <Link className="nav-link" to={`/wholeSale/wshome?id=${id}`}>
            <div className="nav-content">
              <span className="text">HOMEE</span>
              <FaHome className="icon" />
            </div>
          </Link>

          <Link className="nav-link" to={`/wholeSale/wsProfile?id=${id}`}>
            <div className="nav-content">
              <span className="text">Profile</span>
              <ImProfile className="icon" />
            </div>
          </Link>

          <Link className="nav-link" to={`/WholeSale/invoices?id=${id}`}>
            <div className="nav-content">
              <span className="text">Invoices</span>
              <FaFileInvoice className="icon" />
            </div>
          </Link>
        </div>
        <div
          className={`nav-toggle ${isOpen && "open"}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bar"></div>
        </div>
      </div>
      {children}
      <Outlet />
    </div>
  );
};

export default WsNavbar;
