import { useEffect, useState } from "react";
import axios from "axios";

export default function searchByWholeSale(query, pageNumber) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error, setError] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [wholeSales, setWholeSales] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [hasMore, setHasMore] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setWholeSales([]);
  }, [query]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      // url: "http://openlibrary.org/search.json",

      url: `${process.env.REACT_APP_API_URL}/pharmacy/searchByWholeSaleName`,
      params: { q: query, page: pageNumber },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      withCredentials: true,
    })
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);

        setWholeSales((prevwholeSales) => {
          return [
            ...new Set([
              ...prevwholeSales,
              ...res.data.data.map((b) => ({
                companyName: b.companyName,
                id: b.id,
                phoneNumber: b.phoneNumber,
              })),
            ]),
          ];
        });
        setHasMore(res.data.data.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [query, pageNumber]);
  return { loading, error, wholeSales, hasMore };
}
