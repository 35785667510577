import Axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserLocationSelector from "../../helpers/locationinputs/components/adressZoneState";
import CoordinateInput from "../../helpers/locationinputs/components/coordintesInput";
import { useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import addressSchema from "../../helpers/validations/signinInputs/signUpValidation/adressInput";
import "../cssforpath/adressInput.css";
const ThesigninpageAdressws = () => {
  const location = useLocation();
  const data = location.state?.data || {};
  const userId = data;
  console.log("id passed for the address component", userId);

  const [formData, setFormData] = useState({
    region: "",
    zone: "",
    woreda: "",
    kebele: "",
    house_no: "",
    pobox: "",
    street: "",
    latitude: "",
    longitude: "",
    userId: userId,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(addressSchema),
    defaultValues: formData,
  });

  const updateCoordinates = (latitude, longitude) => {
    setFormData({
      ...formData,
      latitude: latitude,
      longitude: longitude,
    });
  };

  const handleLocationSelect = (location) => {
    setFormData({
      ...formData,
      region: location.region,
      zone: location.zone,
      woreda: location.woreda,
    });
    setValue("region", location.region);
    setValue("zone", location.zone);
    setValue("woreda", location.woreda);
  };

  Axios.defaults.withCredentials = true;
  const navigate = useNavigate();

  const onSubmit = (data) => {
    if (data.woreda) {
      console.log("Form data submitted:", data);
      const submitUrl = `${process.env.REACT_APP_API_URL}/wholesale/register/adress`;

      Axios.post(submitUrl, data)
        .then((res) => {
          console.log("Request sent successfully");
          if (res.data.Status === "Success") {
            console.log("Registration successful");
            navigate("/wholeSale/signUpUploadws", { state: { data: userId } });
          } else {
            alert("Unable to register");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("An error occurred during registration");
        });
    } else {
      alert("Woreda is required to submit the form");
    }
  };

  return (
    <div className="container">
      <h2>Address Form</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UserLocationSelector onLocationSelect={handleLocationSelect} />

        <div>
          <label>Kebele:</label>
          <input type="text" {...register("kebele")} />
          <p>{errors.kebele?.message}</p>
        </div>

        <div>
          <label>Street Name:</label>
          <input type="text" {...register("street")} />
          <p>{errors.street?.message}</p>
        </div>

        <div>
          <label>House No:</label>
          <input type="text" {...register("house_no")} />
          <p>{errors.house_no?.message}</p>
        </div>

        <div>
          <label>PO Box:</label>
          <input type="text" {...register("pobox")} />
          <p>{errors.pobox?.message}</p>
        </div>

        <CoordinateInput onCoordinatesUpdate={updateCoordinates} />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ThesigninpageAdressws;
