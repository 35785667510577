import Axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  isValidFileType,
  isValidFileSize,
  generateUniqueFileName,
} from "../../helpers/fileUtils.jsx"; // Adjust the path

const ThesigninpageUploadph = () => {
  const location = useLocation();
  const data = location.state?.datas;
  const userId = data;
  console.log("id passed for the upload component", userId);

  const [licenceForTb, setLicenceForTb] = useState(null);
  const [licenceForGibir, setLicenceForGibir] = useState(null);
  const [error, setError] = useState("");

  const handleFile1Change = (event) => {
    const file1 = event.target.files[0];
    if (file1) {
      if (!isValidFileType(file1) || !isValidFileSize(file1)) {
        setError(
          "Invalid licence. Please select a valid file (JPEG, JPG, PNG, GIF, or PDF) less than 1MB."
        );
        setLicenceForTb(null);
      } else {
        // Change the file name here (e.g., appending a unique identifier)

        const newFileName = generateUniqueFileName(file1.name, "LFTB", userId);
        const renamedFile = new File([file1], newFileName, {
          type: file1.type,
        });
        setLicenceForTb(renamedFile);
        setError("");
      }
    }
  };

  const handleFile2Change = (event) => {
    const file2 = event.target.files[0];
    if (file2) {
      if (!isValidFileType(file2) || !isValidFileSize(file2)) {
        setError(
          "Invalid Revenue. Please select a valid file (JPEG, JPG, PNG, GIF, or PDF) less than 1MB."
        );
        setLicenceForGibir(null);
      } else {
        // Change the file name here (e.g., appending a unique identifier)
        const newFileName = generateUniqueFileName(file2.name, "LFGB", userId);
        const renamedFile = new File([file2], newFileName, {
          type: file2.type,
        });
        setLicenceForGibir(renamedFile);
        setError("");
      }
    }
  };

  Axios.defaults.withCredentials = true;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!licenceForTb || !licenceForGibir) {
      setError("Please select both files to upload.");
      return;
    }
    // Send the form data to the server
    const formData = new FormData();
    formData.append("licenceForTbph", licenceForTb);
    formData.append("licenceForGibirph", licenceForGibir);
    formData.append("userId", userId);
    console.log(
      "licenceForTbph",
      licenceForTb,
      "licenceForGibirph",
      licenceForGibir,
      "the user id it will get on ",
      userId
    );
    const submitUrl = `${process.env.REACT_APP_API_URL}/pharmacy/register/uploads`;
    try {
      const res = await Axios.post(submitUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Request sent successfully");
      if (res.data.Status === "Success") {
        console.log("Registration successful");
        navigate("/login");
      } else {
        setError("Unable to register");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred during registration. Please try again.");
    }
  };

  return (
    <div className="container">
      <h2>Upload Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            Licence for TB:
            <input type="file" onChange={handleFile1Change} />
          </label>
        </div>
        <div className="form-group">
          <label>
            Licence for Gibir:
            <input type="file" onChange={handleFile2Change} />
          </label>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};
export default ThesigninpageUploadph;
