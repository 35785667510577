import React, { useState, useEffect } from "react";
import axios from "axios";
import PopupResonForDiduction from "../POPUPS/DeductionsfromStock/resonForDiduction";
import "./css/editExisting.css";
import MyTry from "../helpers/loading/mytry/myTry";

const MedicineStockDisplay = (props) => {
  const { userId } = props;
  const [medicines, setMedicines] = useState([]);
  const [expandedMedicine, setExpandedMedicine] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [selectedReason, setSelectedReason] = useState("");
  const [originalQuantities, setOriginalQuantities] = useState({});
  const [originalQuotas, setOriginalQuotas] = useState({}); // Track original quotas
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchMedicines = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/wholesale/getExistingstock?id=${userId}`;
        const response = await axios.get(url, { withCredentials: true });
        console.log("🚀 ~ fetchMedicines ~ response:", response);
        setMedicines(response.data.data);

        // Store original stock quantities for comparison
        const originalQuantities = response.data.data.reduce((acc, med) => {
          acc[med.id] = med.stockQuantity;
          return acc;
        }, {});

        const originalQuotas = response.data.data.reduce((acc, med) => {
          acc[med.id] = med.quota; // Store original quota values
          return acc;
        }, {});

        setOriginalQuantities(originalQuantities);
        setOriginalQuotas(originalQuotas); // Set the original quotas
        setLoading(false);
      } catch (error) {
        // Handle the error based on the response message
        if (error.response && error.response.status === 404) {
          const errorMessage = error.response.data.error;
          if (errorMessage === "no medicine found in the data base") {
            // Handle the case where no medicines were found
            console.log("No medicines found in the database.");
            setMedicines([]); // Set medicines to an empty array
          } else {
            // Handle other 404 errors, such as URL not found
            console.error("Error: ", errorMessage);
            setError(errorMessage || "Error fetching medicines");
          }
        } else {
          // Handle other types of errors
          setError("Error fetching medicines");
        }
        setLoading(false);
      }
    };
    fetchMedicines();
  }, [userId]);

  const handleToggleExpand = (index) => {
    setExpandedMedicine((prev) => (prev === index ? null : index));
  };

  const handleStockCountChange = (index, change) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index].stockQuantity += change;
    setMedicines(updatedMedicines);
  };

  const handleQuotaChange = (index, newQuota) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index].quota = newQuota; // Update the quota value
    setMedicines(updatedMedicines);
  };

  const handleSubmitChanges = (index, medicine) => {
    console.log("🚀 ~ handleSubmitChanges ~ index:", index);
    console.log("🚀 ~ handleSubmitChanges ~ medicine:", medicine);
    const originalQuantity = originalQuantities[medicine.id];
    const updatedQuantity = medicines[index].stockQuantity;
    const originalQuota = originalQuotas[medicine.id];
    const updatedQuota = medicines[index].quota;

    // If there's any change to stockQuantity or quota, submit the changes
    if (
      updatedQuantity !== originalQuantity ||
      updatedQuota !== originalQuota
    ) {
      if (updatedQuantity < originalQuantity) {
        setCurrentAction({
          type: "deduct",
          medicine,
          originalQuantity,
          updatedQuantity,
        });
        setIsPopupOpen(true);
      } else {
        submitStockChange(medicine.id, updatedQuantity, updatedQuota);
      }
    }
  };

  const submitStockChange = async (id, stockQuantity, quota) => {
    setLoading(true); // Show loading before making the request
    console.log("🚀 ~ submitStockChange ~ quota:", quota);
    try {
      const submitUrl = `${process.env.REACT_APP_API_URL}/wholesale/updateAStockQuantityValue?id=${id}`;
      await axios.put(
        submitUrl,
        { stockQuantity, quota },
        { withCredentials: true }
      );
      console.log("Stock quantity and quota updated successfully");
    } catch (error) {
      console.error("Error submitting stock changes:", error);
    } finally {
      setLoading(false); // Hide loading after the request finishes
    }
  };

  const handleDelete = (medicine) => {
    setCurrentAction({ type: "delete", medicine });
    setIsPopupOpen(true);
  };

  const handlePopupSelect = async (reason) => {
    setLoading(true); // Show loading while handling popup select
    setSelectedReason(reason);

    // Get the current quota for the selected medicine
    const currentQuota =
      currentAction.type === "deduct"
        ? medicines[
            medicines.findIndex((med) => med.id === currentAction.medicine.id)
          ].quota
        : 0; // Default to 0 for delete action
    try {
      const deductUrl = `${process.env.REACT_APP_API_URL}/wholesale/deductStock`;

      if (currentAction.type === "deduct") {
        const { medicine, originalQuantity, updatedQuantity } = currentAction;
        const amountDeducted = originalQuantity - updatedQuantity;

        console.log(
          "🚀 ~ handlePopupSelectdeduct ~ amountDeducted:",
          amountDeducted,
          selectedReason
        );
        console.log(
          "🚀 ~ handlePopupSelectdeducted ~ medicineId:",
          medicine.id
        );
        console.log("🚀 ~ handlePopupSelectdeduct ~ reason:", reason);
        console.log("🚀 ~ handlePopupSelect ~ quota:", currentQuota);

        await axios.post(
          deductUrl,
          {
            medicineId: medicine.id,
            amountDeducted,
            reason,
            userId,
            quota: currentQuota, // Include current quota here
          },
          { withCredentials: true }
        );

        submitStockChange(medicine.id, updatedQuantity, currentQuota);
      } else if (currentAction.type === "delete") {
        const { medicine } = currentAction;

        console.log("🚀 ~ handlePopupSelectdelete ~ medicine:", medicine.id);
        console.log(
          "🚀 ~ handlePopupSelectdelete ~ medicine:",
          medicine.stockQuantity
        );
        console.log("🚀 ~ handlePopupSelectdelete ~ medicine:", reason);

        await axios.post(
          deductUrl,
          {
            medicineId: medicine.id,
            amountDeducted: medicine.stockQuantity,
            reason,
            userId,
            quota: currentQuota, // Pass quota for delete action as well
          },
          { withCredentials: true }
        );

        setMedicines(medicines.filter((med) => med.id !== medicine.id));
      }
    } catch (error) {
      console.error("Error handling popup select:", error);
    } finally {
      setLoading(false); // Hide loading after the request finishes
    }

    setIsPopupOpen(false);
  };

  return (
    <div className="medicinesSection">
      <h2>Medicines in Stock</h2>
      {loading && <MyTry />} {/* Loading state */}
      {error && <p>{error}</p>} {/* Error state */}
      {medicines.length === 0 && !loading && !error && <p>Empty stock</p>}
      {medicines.length > 0 && (
        <ul>
          {medicines.map((medicine, index) => (
            <li key={index}>
              <div onClick={() => handleToggleExpand(index)}>
                <span>{medicine.medicineName}</span>
                <span>{medicine.stockQuantity} units</span>
                <span>Quota: {medicine.quota}</span> {/* Display quota */}
              </div>
              {expandedMedicine === index && (
                <div>
                  <p>Brand: {medicine.brandName}</p>
                  <p>Expiration Date: {medicine.expDate}</p>
                  <button onClick={() => handleStockCountChange(index, -1)}>
                    -
                  </button>
                  <button onClick={() => handleStockCountChange(index, 1)}>
                    +
                  </button>
                  <input
                    type="number"
                    value={medicine.quota}
                    onChange={(e) => handleQuotaChange(index, e.target.value)}
                  />
                  {/* Input to change quota */}
                  <button onClick={() => handleSubmitChanges(index, medicine)}>
                    Submit Changes
                  </button>
                </div>
              )}
              <button
                className="deleteButton"
                onClick={() => handleDelete(medicine)}
              >
                Delete
              </button>
              <p>Beginning Balance: {medicine.biginningBalance}</p>
            </li>
          ))}
        </ul>
      )}
      <PopupResonForDiduction
        isOpen={isPopupOpen}
        options={[
          "sold_door_to_door",
          "Institution",
          "Incentive",
          "f_s",
          "Transfer",
          "Damage",
          "Loss",
          "EFDA_QT",
          "Expired_on_hand",
          "Exchange_for_expired",
          "Unknown",
        ]}
        onClose={() => setIsPopupOpen(false)}
        onSelect={handlePopupSelect}
      />
    </div>
  );
};

export default MedicineStockDisplay;
