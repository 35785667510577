import React from "react";
import FavoriteButton from "./components/favorite";
import "./css/resultsComponent.css";
import CustomLink from "../../helpers/customeLink/resetProductsWhenDffWs";

const ResultsComponent = ({ wholeSale, ownId }) => {
  console.log("🚀 ~ ResultsComponent ~ ownId:", ownId)
  console.log("🚀 ~ ResultsComponent ~ wholeSale:", wholeSale);
  return (
    <div className="results-component">
      <CustomLink
        to={`/pharmacy/shopFromWholeSale?wholeSaleId=${wholeSale.id}&ownId=${ownId}&WsName=${wholeSale.companyName}`}
      >
        {wholeSale.companyName}
      </CustomLink>
      <div className="favorite-button">
        <FavoriteButton contact={wholeSale.id} ownId={ownId} />
      </div>
    </div>
  );
};

export default ResultsComponent;
