import { useEffect, useState } from "react";
import axios from "axios";

export default function searchByForMyStock(
  query,
  sortField,
  sortOrder,
  pageNumber,
  ownId
) {
  console.log("🚀 ~ SearchByDate ~ pageNumber:", pageNumber);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error, setError] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [products, setProducts] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [hasMore, setHasMore] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setProducts([]);
    console.log("🚀 ~ sortOrder:", sortOrder);
    console.log("🚀 ~ sortField:", sortField);
  }, [query, sortField, sortOrder, ownId]);
  console.log("🚀 ~ sortOrder:", sortOrder);
  console.log("🚀 ~ sortField:", sortField);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/wholesale/myMedHistory?id=${ownId}`,
      params: {
        q: query,
        page: pageNumber,
        sortField: sortField,
        sortOrder: sortOrder,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      withCredentials: true,
    })
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);

        setProducts((prevMedicines) => {
          return [
            ...new Set([
              ...prevMedicines,
              ...res.data.data.map((b) => ({
                medicineName: b.medicineName,
                batchNo: b.batchNo,
                ExpDate: b.ExpDate,
                datePostedOn: b.datePostedOn,
                beginningBalance: b.beginningBalance,
                brandName: b.brandName,
                description: b.description,
                dosage: b.dosage,
                medId: b.medId,
                availableOnHand: b.availableOnHand,
              })),
            ]),
          ];
        });
        setHasMore(res.data.data.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [query, pageNumber, ownId, sortOrder, sortField]);
  return { loading, error, products, hasMore };
}
