import * as yup from "yup";

const signInSchema1 = (userType) =>
  yup.object().shape({
    username: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    companyName: yup.string().required("Your company name is required"),
    phoneNumber: yup
      .string()
      .length(10, "To Small For A PhoneNumber")
      .matches(/^09\d{8}$/, "Doesn't meet ethio telecom issued Phone number")
      .required("Phone number is required"),
    password: yup
      .string()
      .min(4, "Password must be at least 4 characters")
      .max(15, "Password must be less than 15 characters")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords do not match")
      .required("Please confirm your password"),
    licenceNumber: yup.string().required("Licence number is required"),
    tinNumber: yup.string().required("TIN number is required"),

    // Conditionally require buyerOption only for Buyers
    buyerOption:
      userType === "Buyer"
        ? yup.string().required("User type is required.")
        : yup.string().notRequired(),
  });

export default signInSchema1;
