import React from "react";
import { useLocation} from "react-router-dom";
import { faPills, faStethoscope } from "@fortawesome/free-solid-svg-icons";
import "./CSS/ShopPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomLink from "../helpers/customeLink/resetProductsWhenDffWs";

const ShopPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const wholeSaleId = searchParams.get("wholeSaleId");
  const ownId = searchParams.get("ownId");
  const companyName = searchParams.get("WsName");


  return (
    <div className="shop-page">
      <h1>Welcome to {companyName} Order Page</h1>
      <div className="button-container">
        <CustomLink
          className="shop-button"
          to={`/pharmacy/shopForMedicine?wholeSaleId=${wholeSaleId}&ownId=${ownId}&WsName=${companyName}`}
        >
          <button className="shop-button">
            <FontAwesomeIcon icon={faPills} className="icon" />
            Shop for Medicine
          </button>
        </CustomLink>
        <CustomLink
          className="shop-button"
          to={`/pharmacy/shopForMedicalSupply?wholeSaleId=${wholeSaleId}&ownId=${ownId}&WsName=${companyName}`}
        >
          <button
            className="shop-button"
          >
            <FontAwesomeIcon icon={faStethoscope} className="icon" />
            Shop for Medical Supplies
          </button>
        </CustomLink>
      </div>
    </div>
  );
};

export default ShopPage;
