import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ProductBinCard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [runningTotal, setRunningTotal] = useState(null); // Keep the runningTotal state
  const [mismatchError, setMismatchError] = useState(null);

  const location = useLocation();
  const productId = location.state?.data;

  useEffect(() => {
    const fetchData = async () => {
      if (!productId) {
        setError("Product ID is missing");
        setLoading(false);
        return;
      }

      try {
        const fetchUrl = `${process.env.REACT_APP_API_URL}/wholesale/binCard?id=${productId}`;
        const response = await axios.get(fetchUrl, { withCredentials: true });
        setData(response.data);
      } catch (err) {
        setError("Failed to fetch data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [productId]);

  const calculateRunningTotal = useCallback(
    (transactions) => {
      let total = data.beginningBalance; // Start with the beginning balance
      transactions.forEach((transaction) => {
        total -= transaction.amount; // Subtract each transaction amount
      });
      setRunningTotal(total); // Update the runningTotal state
      if (total !== data.currentQuantity) {
        setMismatchError("Running total does not match the current quantity.");
      } else {
        setMismatchError(null);
      }
    },
    [data]
  );

  useEffect(() => {
    if (data && data.transactions) {
      calculateRunningTotal(data.transactions);
    }
  }, [data, calculateRunningTotal]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const transactions = data?.transactions || [];
  const reasons = [
    "sold",
    "sold_door_to_door",
    "Institution",
    "Incentive",
    "f_s",
    "Transfer",
    "Damage",
    "Loss",
    "EFDA_QT",
    "Expired_on_hand",
    "Exchange_for_expired",
    "Unknown",
  ];

  const reasonAmounts = transactions.reduce((acc, transaction) => {
    if (!acc[transaction.date]) {
      acc[transaction.date] = reasons.reduce((reasonAcc, reason) => {
        reasonAcc[reason] = 0;
        return reasonAcc;
      }, {});
    }
    acc[transaction.date][transaction.reason] += transaction.amount;
    return acc;
  }, {});

  return (
    <div>
      <h1>Still in development please wait patiently </h1>
      <h1>Product Bin Card</h1>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Reason</th>
            {reasons.map((reason, index) => (
              <th key={index}>{reason}</th>
            ))}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.date}</td>
            <td>Beginning Balance</td>
            {reasons.map((_, index) => (
              <td key={index}>{index === 0 ? data.beginningBalance : ""}</td>
            ))}
            <td>{data.beginningBalance}</td>
          </tr>
          {Object.keys(reasonAmounts).map((date, index) => (
            <tr key={index}>
              <td>{date}</td>
              <td>{`Transaction ${index + 1}`}</td>
              {reasons.map((reason, reasonIndex) => (
                <td key={reasonIndex}>
                  {reasonAmounts[date][reason] || ""}
                </td>
              ))}
              <td>
                {reasons.reduce(
                  (sum, reason) => sum + reasonAmounts[date][reason],
                  0
                )}
              </td>
            </tr>
          ))}
          <tr>
            <td>{data.date}</td>
            <td>Current Quantity</td>
            {reasons.map((_, index) => (
              <td key={index}>{index === 0 ? runningTotal : ""}</td> 
            ))}           <td>{runningTotal}</td> {/* Display the final running total */}
          </tr>
        </tbody>
      </table>
      {mismatchError && <p style={{ color: "red" }}>{mismatchError}</p>}
    </div>
  );
};

export default ProductBinCard;
