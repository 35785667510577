import React, { useContext } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { ShopContext } from "../context/shopContext";
import { CartItem } from "../cartCard";

import axios from "axios";
import "../CSS/cart.css";

const Cart = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const wholeSaleId = searchParams.get("wholeSaleId");
  console.log("🚀 ~ Cart ~ wholeSaleId:", wholeSaleId);
  const ownId = searchParams.get("ownId");
  console.log("🚀 ~ Cart ~ ownId:", ownId);

  const {
    cartItems,
    getTotalCartAmount,
    getProductedData,
    resetCartAndProducts,
  } = useContext(ShopContext);

  axios.defaults.withCredentials = true;
  // const navigate = useNavigate();
  const totalAmount = getTotalCartAmount();
  const PRODUCTS = getProductedData(); // Retrieve product data
  const history = useNavigate();
  const submitBtn = (e) => {
    e.preventDefault();
    const formDataToSend = {
      wholeSaleId: wholeSaleId,
      ownId: ownId,
      cartItems: cartItems,
    };
    console.log("Form Data:", formDataToSend);

    const submitUrl = `${process.env.REACT_APP_API_URL}/pharmacy/makeAnOrder/setOrder`;

    axios
      .post(submitUrl, formDataToSend)
      .then((res) => {
        console.log("ORDER SET SUCCESSFUL");
        if (res.data.Status === "Success") {
          console.log("Order successful");
          //Call resetCartAndProducts upon successful order
          resetCartAndProducts();
          history(`/pharmacy/phHomesearchByWholeSale?id=${ownId}`);
        } else {
          alert("unable to register");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred during seting order");
      });

    console.log("here here", cartItems);
  };

  return (
    <div className="cart">
      <div className="title">
        <h1>Your Cart Items</h1>
      </div>
      <div className="cartItems">
        {PRODUCTS.map((product) => {
          if (cartItems[product.id] !== 0) {
            return <CartItem key={product.id} data={product} />;
          }
          return null;
        })}
      </div>
      {totalAmount > 0 ? (
        <div className="checkOut">
          <p>Subtotal: ${totalAmount}</p>
          <button onClick={submitBtn}>Checkout</button>
          <Link
            to={`/pharmacy/shopForMedicine?wholeSaleId=${wholeSaleId}&ownId=${ownId}`}
          >
            <button>Continue Shopping</button>
          </Link>
        </div>
      ) : (
        <div className="checkOut">
          <h1>Your shopping cart is empty</h1>
          <button
            onClick={() =>
              history(
                `/pharmacy/shopForMedicine?wholeSaleId=${wholeSaleId}&ownId=${ownId}`
              )
            }
          >
            Continue Shopping
          </button>
        </div>
      )}
    </div>
  );
};

export default Cart;
