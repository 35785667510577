import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ShopContext } from "../../orderFolder/context/shopContext";

const CustomLink = ({ to, children, ...props }) => {
  const { resetCartAndProducts } = useContext(ShopContext);
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    resetCartAndProducts();
    navigate(to);
  };

  return (
    <a href={to} onClick={handleClick} {...props}>
      {children}
    </a>
  );
};

export default CustomLink;
