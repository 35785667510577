import React, { useState, useRef, useCallback } from "react";
import searchByWholeSale from "./searchByWholeSale";
import { useLocation } from "react-router-dom";
import ResultsComponent from "./ResultsComponent";
import "./css/searchByWholeSaleName.css";
import MyTry from "../../helpers/loading/mytry/myTry";

export default function SearchByGeneral() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ownId = searchParams.get("id");
  const [query, setQuery] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  const { wholeSales, hasMore, loading, error } = searchByWholeSale(
    query,
    pageNumber
  );
  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    setQuery(e.target.value);
    setPageNumber(1);
  }

  return (
    <div className="page-container">
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        placeholder="Enter wholeSale Name"
      />

      <div className="results-container">
        {wholeSales.map((wholeSale, index) => {
          if (wholeSales.length === index + 1) {
            return (
              <div ref={lastBookElementRef} key={wholeSale.id}>
                <ResultsComponent wholeSale={wholeSale} ownId={ownId} />
              </div>
            );
          } else {
            return (
              <div key={wholeSale.id}>
                <ResultsComponent wholeSale={wholeSale} ownId={ownId} />
              </div>
            );
          }
        })}
      </div>

      <div>{loading && <MyTry />}</div>
      <div className="error">{error && "error..."}</div>
    </div>
  );
}
