import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import "./css/cssforAddNewStock.css"; // Import your CSS file
import Axios from "axios";
import { useLocation } from "react-router-dom";
import MyTry from "../helpers/loading/mytry/myTry";

const MedicineFormAdd = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("id");

  // State to manage user input and filtered options
  const [medInputValue, setInputValue] = useState("");
  // New state to control whether to show similar searches
  const [showSimilarSearches, setShowSimilarSearches] = useState(true);

  // New state to store the selected option's ID
  const [selectedDosageForms, setSelectedDosageForms] = useState([]);
  // Placeholder for extracted medicine names
  const [extractedMedicineNames, setExtractedMedicineNames] = useState([]);
  // State to manage loading
  const [loading, setLoading] = useState(true);
  // New state to track whether a medicine has been chosen
  const [medicineChosen, setMedicineChosen] = useState(false);
  const [chosenMedDetail, setChosenMedDetail] = useState([]);

  const [uniqueOptions, setUniqueOptions] = useState([]);
  const [medicineName, setMedicineName] = useState("");
  const [dosage, setDosage] = useState("");
  const [dose, setDose] = useState("");
  const [brandName, setBrandName] = useState("");
  const [stockQuantity, setStockQuantity] = useState("");
  const [expDate, setExpDate] = useState("");

  const [quota, setQuota] = useState("");
  const [batchNum, setBatchNum] = useState("");
  const [unitOfIssue, setUnitOfIssue] = useState("");
  const [pricePerUnit, setPricePerUnit] = useState("");
  const [notes, setNotes] = useState("");

  // Placeholder functions for dosage and dose changes

  const handleDoseChange = (e) => {
    const selectedDose = e.target.value;
    // Update state or perform actions based on the selected dose
    setDose(selectedDose);
    console.log(dose);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    if (!medicineName || !pricePerUnit || !stockQuantity) {
      alert(
        "Please fill in all required fields: Medicine Name, Price Per Unit, Stock Quantity"
      );
      return;
    }

    // Ensure stockQuantity is at least 1
    if (stockQuantity < 1) {
      alert("Stock Quantity must be at least 1.");
      return;
    }
    
    // You can handle the form submission logic here
    const formData = {
      medicineName: medicineName,
      dosage: dosage,
      dose: dose,
      brandName: brandName,
      stockQuantity: stockQuantity,
      expDate: expDate,

      quota: quota,
      batchNum: batchNum,
      unitOfIssue: unitOfIssue,
      pricePerUnit: pricePerUnit,
      notes: notes,
      userId: userId,
    };

    console.log("form data", formData);
    // Send the form data to the server
    const url = `${process.env.REACT_APP_API_URL}/wholesale/UploadNewStock`;
    Axios.post(url, formData, {
      withCredentials: true, // Enable sending cookies with the request
    })
      .then((res) => {
        console.log("Request sent successfully");
        if (res.data.Status === "Success") {
          console.log("medicine add successful");
          setInputValue("");
          setMedicineName("");
          setDosage("");
          setDose("");
          setBrandName("");
          setStockQuantity("");
          setExpDate("");

          setQuota("");
          setBatchNum("");
          setUnitOfIssue("");
          setPricePerUnit("");
          setNotes("");
        } else {
          alert("unable to add med");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred during medicine  addition.");
      });
  };
  useEffect(() => {
    // Create an async function to use await
    const fetchData = async () => {
      try {
        // Only fetch data if a medicine has not been chosen
        if (!medicineChosen) {
          // Dynamically import medNamesData
          const { default: medNamesData } = await import(
            "../helpers/components/listOfMed"
          );
          console.log("🚀 ~ fetchData ~ medNamesData:", medNamesData);

          // Process and set the extracted medicine names based on the imported data
          const processedMedicineNames = [];

          medNamesData.groupMedicines.forEach((group) => {
            console.log("Group:", group);
            group.medicines.forEach((medicine) => {
              console.log("Medicine:", medicine);
              medicine.generalMedicineName.forEach((medicineNameObj) => {
                const { id, name, dosageForms } = medicineNameObj;
                const dosageFormData = dosageForms.map(
                  ({ dosageFormId, dosage }) => ({
                    dosageFormId,
                    dosage,
                  })
                );
                processedMedicineNames.push({
                  id,
                  name,
                  dosageForms: dosageFormData,
                });
              });
            });
          });
          // Set the extracted medicine names in the state
          setExtractedMedicineNames(processedMedicineNames);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Set loading to false after fetching data (whether success or error)
        setLoading(false);
      }
    };
    // Call the async function only when medInputValue changes
    if (medInputValue) {
      fetchData();
    }
  }, [medInputValue, medicineChosen]); // Dependency array includes medInputValue

  useEffect(() => {
    console.log("the use effect for the dosage data importer run");
    // Create an async function to use await
    const fetchDosageData = async () => {
      try {
        // Only fetch data if a medicine has not been chosen
        if (selectedDosageForms && selectedDosageForms.length > 0) {
          // Dynamically import medNamesData
          const { default: medDosageData } = await import(
            "../helpers/components/listOfMedDosage"
          );

          // Create an object to store dosage forms by ID
          const dosageFormsById = {};

          // Loop through each selected dosageForm
          for (const dosageForm of selectedDosageForms) {
            const dosageFormId = dosageForm.dosageFormId;

            if (dosageFormId !== undefined) {
              // Find the dosage form by ID in medDosageData
              const dosageNames = medDosageData.dosageForms.find(
                (dosage) => dosage.id === dosageFormId
              );
              // Rest of your code...

              if (dosageNames) {
                // Trim and lowercase the dosage form name
                const trimmedName = dosageNames.name.trim().toLowerCase();

                // Initialize an array for storing dosages for this ID
                dosageFormsById[trimmedName] =
                  dosageFormsById[trimmedName] || [];

                // Combine dosages for the same ID
                dosageFormsById[trimmedName].push(...dosageForm.dosage);
                // Update the state with the name and dosage of the selected dosage form
              } else {
                console.log("Dosage form ID not found:", dosageFormId);
              }
            } else {
              console.log("Dosage form ID is undefined");
            }
          }

          // Convert the combined dosages back into an array of objects
          const chosenDosageDetail = Object.entries(dosageFormsById).map(
            ([name, dosage]) => ({
              name,
              dosage,
            })
          );

          // Update the state with the combined dosage details
          setChosenMedDetail(chosenDosageDetail);
        } else {
          console.log("No selected dosage forms");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Set loading to false after fetching data (whether success or error)
        setLoading(false);
      }
    };
    if (selectedDosageForms) {
      fetchDosageData();
    }
  }, [selectedDosageForms]); // Dependency array includes medInputValue

  // Create a new Fuse instance for searching medicine options
  const fuseMedNames = new Fuse(extractedMedicineNames, {
    keys: ["name"], // Specify the property to search on
    includeScore: true,
  });

  const handleMedInputChange = (e) => {
    const medInputValue = e.target.value;
    setInputValue(medInputValue);

    // Use Fuse to filter options based on input
    const searchResults = fuseMedNames.search(medInputValue);
    // Extract unique options with the same name
    console.log("searchResults:", searchResults);

    // Check if searchResults is not empty before further processing
    if (searchResults.length > 0) {
      console.log("there is a result", searchResults);
      const uniqueOptionsArray = Array.from(
        searchResults.reduce((nameMap, result) => {
          const { name, id, dosageForms } = result.item;

          // Convert the name to lowercase, trim leading and trailing spaces
          const processedName = name.toLowerCase().trim();

          // Check if the processed name is not empty
          if (processedName) {
            // Use the exact processed name as a key to store the ID(s)
            const existingData = nameMap.get(processedName) || {
              ids: [],
              dosageForms: [],
            };
            nameMap.set(processedName, {
              ids: [...existingData.ids, id],
              dosageForms: [...existingData.dosageForms, ...dosageForms],
            });
          }
          return nameMap;
        }, new Map())
      ).map(([name, ids, dosageForms]) => ({ name, ids, dosageForms }));
      // Update the state with the unique options
      setUniqueOptions(uniqueOptionsArray);
      console.log(
        "🚀 ~ handleMedInputChange ~ uniqueOptionsArray:",
        uniqueOptions
      );
      // Set showSimilarSearches to true when there are results
      setShowSimilarSearches(true);
    } else {
      console.log("there is no result");
      setUniqueOptions(["choose a medicine"]);
      // Set showSimilarSearches to false when there are no results
      setShowSimilarSearches(false);
    }
  };
  const handleMedSelect = (selectedOption, e) => {
    console.log(
      "🚀 ~ handleMedSelect ~ selectedOption:",
      selectedOption.ids.dosageForms
    );
    // Prevent the default form submission behavior
    e.preventDefault();
    
    // Update the chosenOptionId state with the selected option's ID
    setSelectedDosageForms(selectedOption.ids.dosageForms);
    console.log("this should get me the dosage names", selectedDosageForms);
    // Update the medicineName state with the selected option's name
    setMedicineName(selectedOption.name);
    setDosage("");
    setDose("");
    // Update the input value to the selected option's name
    setInputValue(selectedOption.name);

    setShowSimilarSearches(false);
    // Set medicineChosen to true to prevent further data fetching
    setMedicineChosen(true);
  };

  const handleDosageSelect = (e) => {
    e.preventDefault();
    const selectedDosageName = e.target.value;

    // Find the selected dosage details based on the selected name
    const selectedDosageDetails = chosenMedDetail.find(
      (detail) => detail.name === selectedDosageName
    );

    // Update the dosage state with the selected dosage's name
    setDosage(selectedDosageName);

    // Update the dose state with the selected dosage's details
    setDose(selectedDosageDetails.dosage);

    // Log the selected dosage details
    console.log("Selected Dosage Details:", selectedDosageDetails);
  };

  return (
    <div className="container">
      <h1>Add Medicine for Sale</h1>
      <form onSubmit={handleSubmit}>
        {/* Medicine Name */}
        <div className="form-group">
          <label>
            Medicine Name:
            <div className="input-container">
              {/* Input for autocomplete */}
              <input
                type="text"
                value={medInputValue}
                onChange={handleMedInputChange}
                placeholder="Type to filter options"
              />
              {/* Display filtered options */}
              {loading ? (
                <MyTry />
              ) : (
                <div
                  style={{
                    maxHeight: "150px", // Adjust the height as needed
                    overflow: "auto",
                  }}
                >
                  <ul style={{ margin: "10px 0", padding: 0 }}>
                    {showSimilarSearches &&
                      uniqueOptions.map((option, index) => (
                        <li
                          key={index}
                          onClick={(e) => handleMedSelect(option, e)}
                          style={{ marginBottom: "5px" }}
                        >
                          {option.name}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </label>
        </div>
        <div className="form-group">
          {/* Dropdown for selecting dosage */}
          <div className="chooseDOSAGE">
            <label htmlFor="DOSAGES">Select a DOSAGE:</label>
            <select
              id="dosage"
              onChange={handleDosageSelect}
              value={dosage}
              disabled={!chosenMedDetail} // Disable if no medicine is selected
            >
              <option value="">Select a Dosage</option>
              {/* Map over chosenMedDetail array to render options */}
              {chosenMedDetail.map((dosageOption, index) => (
                <option key={index} value={dosageOption.name}>
                  {dosageOption.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          {/* Dropdown for selecting dose */}
          <div className="chooseDose">
            <label htmlFor="DOSE">Select a Dose:</label>
            <select
              id="dose"
              onChange={handleDoseChange}
              value={dose}
              disabled={!dosage} // Disable if no dosage is selected
            >
              <option value="">Select a Dose</option>
              {/* Options based on the selected dosage */}
              {/* Replace doseData with the actual data structure */}
              {chosenMedDetail
                .find((detail) => detail.name === dosage) // Find the selected dosage detail
                ?.dosage.map((doseOption, index) => (
                  <option key={index} value={doseOption}>
                    {doseOption}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          {/* Brand Name */}
          <label>
            Brand Name:
            <input
              type="text"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
            />
          </label>
        </div>

        <div className="form-group">
          {/* Stock Quantity */}
          <label>
            Stock Quantity:
            <input
              type="number"
              value={stockQuantity}
              onChange={(e) => setStockQuantity(e.target.value)}
            />
          </label>
        </div>

        <div className="form-group">
          {/* Expiration Date */}
          <label>
            Expiration Date:
            <input
              type="date"
              value={expDate}
              onChange={(e) => setExpDate(e.target.value)}
            />
          </label>
        </div>

        <div className="form-group">
          {/* quota */}
          <label>
            Quota:
            <input
              type="number"
              value={quota}
              onChange={(e) => setQuota(e.target.value)}
            />
          </label>
        </div>

        <div className="form-group">
          {/* Batch Number */}
          <label>
            Batch Number:
            <input
              type="text"
              value={batchNum}
              onChange={(e) => setBatchNum(e.target.value)}
            />
          </label>
        </div>

        <div className="form-group">
          {/* Unit of Issue */}
          <label>
            Unit of Issue:
            <input
              type="text"
              value={unitOfIssue}
              onChange={(e) => setUnitOfIssue(e.target.value)}
            />
          </label>
        </div>

        <div className="form-group">
          {/* Price Per Unit */}
          <label>
            Price Per Unit:
            <input
              type="number"
              value={pricePerUnit}
              onChange={(e) => setPricePerUnit(e.target.value)}
            />
          </label>
        </div>

        <div className="form-group">
          {/* Notes */}
          <label>
            Notes:
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </label>
        </div>

        <div className="form-group">
          {/* Submit Button */}
          <button type="submit">Add Medicine</button>
        </div>
      </form>
      {/* Display Section */}
    </div>
  );
};

export default MedicineFormAdd;
