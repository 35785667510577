import React, { useContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./AfrerLoginNav.css";
import { IDContext } from "../helpers/contextProvider/IDContext";
import { FaFileInvoice, FaHome } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import test2 from "../helpers/logo/Untitled1.svg";

const PhNavbar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useContext(IDContext);

  return (
    <div>
      <div className="Navbar">
        <span className="nav-logo">
          <img className="logo" src={test2} alt="" />
        </span>
        <div className={`nav-items ${isOpen ? "open" : ""}`}>
          <Link className="nav-link" to={`/pharmacy/phhome?id=${id}`}>
            <div className="nav-content">
              <span className="text">HOME</span>
              <FaHome className="icon" />
            </div>
          </Link>

          <Link className="nav-link" to={`/pharmacy/phProfile?id=${id}`}>
            <div className="nav-content">
              <span className="text">Profile</span>
              <ImProfile className="icon" />
            </div>
          </Link>

          <Link className="nav-link" to={`/pharmacy/invoices?id=${id}`}>
            <div className="nav-content">
              <span className="text">Invoices</span>
              <FaFileInvoice className="icon" />
            </div>
          </Link>
        </div>
        <div
          className={`nav-toggle ${isOpen && "open"}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bar"></div>
        </div>
      </div>
      {children}
      <Outlet />
    </div>
  );
};

export default PhNavbar;
