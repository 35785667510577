/* includes
      search by name, holesale, brand
      profiles
      may be ordered page */
import React, { useEffect, useState, lazy, Suspense, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { IDContext } from "../../helpers/contextProvider/IDContext";
import MyTry from "../../helpers/loading/mytry/myTry";

const ResultsComponent = lazy(() => import("./WshpResultsComponent"));
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <p>Oops! Something went wrong.</p>; // Render a fallback UI
    }

    return this.props.children;
  }
}
const WsHomePage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const { setId } = useContext(IDContext);
  useEffect(() => {
    if (id) {
      setId(id);
    }
  }, [id, setId]);

  useEffect(() => {
    const fetchData = async () => {
      const url1 = `${process.env.REACT_APP_API_URL}/wholeSale/home?id=${id}`;
      try {
        const response = await axios.get(url1, {
          withCredentials: true, // Enable sending cookies with the request
        });
        setData(response.data);
        console.log(response);
        console.log(response.cookies);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setError(true);
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  if (isLoading) {
    return (
     
        <MyTry />
      
    );
  }
  if (error) {
    console.error("Error:", error);
    return (
      <div>
        <h1>Error while fetching the data</h1>
        <Link to="/login">
          <h2>Try logging in again</h2>
        </Link>
      </div>
    );
  }

  if (!data) {
    return (
      <div>
        <h1>Correct user information but couldn't get the page</h1>
        <Link to="/login">
          <h2>Try logging in again</h2>
        </Link>
      </div>
    );
  }
  return (
    <div>
      <Suspense fallback={<MyTry/>}>
        <ErrorBoundary>
          <ResultsComponent data={data} userId={id} />
        </ErrorBoundary>
      </Suspense>
    </div>
  );
};

export default WsHomePage;
