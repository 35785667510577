/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from "react";
import searchByForPh from "./searchForMyMedicine";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/medicineHistory.css";
import MyTry from "../../helpers/loading/mytry/myTry";

export default function MedicinesSearchAndHistory() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ownId = searchParams.get("id");
  console.log("🚀 ~ MedicinesSearchAndHistory ~ ownId:", ownId);
  const [query, setQuery] = useState("");
  const [sortField, setSortField] = useState("ExpDate");
  const [sortOrder, setSortOrder] = useState("asc");
  const [pageNumber, setPageNumber] = useState(1);
  const { products, hasMore, loading, error } = searchByForPh(
    query,
    sortField,
    sortOrder,
    pageNumber,
    ownId
  );
  const observer = useRef();
  const navigate = useNavigate();

  console.log("🚀 ~ productsearchByGeneral ~ products:", products);

  const lastproductsElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    setQuery(e.target.value);
    setPageNumber(1);
  }

  function handleSort(field) {
    const newSortOrder =
      sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newSortOrder);
    setPageNumber(1);
  }

  function handleProductsClick(medId) {
    navigate("/WholeSale/productsBinCard", {
      state: { data: medId },
    });
  }

  return (
    <div className="products-container">
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        placeholder="Search products..."
        className="search-input"
      />
      <table className="products-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("id")}>
              Med Id
              {sortField === "id" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("drug_name")}>
              Medicine Name
              {sortField === "drug_name" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("batchNum")}>
              Batch Number
              {sortField === "batchNum" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("drug_expdate")}>
              Exp Date
              {sortField === "drug_expdate" &&
                (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("dare")}>
              Stocked On
              {sortField === "dare" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("biginning_balance")}>
              Beginning Balance
              {sortField === "biginning_balance" &&
                (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("brand_name")}>
              Brand Name
              {sortField === "brand_name" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("description")}>
              Description
              {sortField === "description" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("dosage")}>
              Dosage
              {sortField === "dosage" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("on_hand_quantity")}>
              Is Available
              {sortField === "on_hand_quantity" &&
                (sortOrder === "asc" ? "↑" : "↓")}
            </th>
          </tr>
        </thead>
        <tbody>
          {products.length > 0 ? (
            products.map((products, index) => (
              <tr
                key={products.medId}
                ref={
                  index === products.length - 1 ? lastproductsElementRef : null
                }
                onClick={() => handleProductsClick(products.medId)}
              >
                <td>{products.medId}</td>
                <td>{products.medicineName}</td>
                <td>{products.batchNo}</td>
                <td>{products.ExpDate}</td>
                <td>{products.datePostedOn}</td>
                <td>{products.beginningBalance}</td>
                <td>{products.brandName}</td>
                <td>{products.description}</td>
                <td>{products.dosage}</td>
                <td>{products.availableOnHand}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No products found</td>
            </tr>
          )}
        </tbody>
      </table>
      {loading && <MyTry />}
      {error && (
        <div className="error-message">
          Error: {error.message || "An error occurred"}
        </div>
      )}
    </div>
  );
}
