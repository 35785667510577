import React from "react";
import { Link, useLocation } from "react-router-dom";
import MedicineStockDisplay from "./editExistingStock";
import "./css/createBach.css";

const MedicineForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  return (
    <div className="addMedicineSection">
      <h1>Add Medicine for Sale</h1>
      {/* Link to the add-medicine route */}
      <div className="buttons">
        <Link to={`/wholeSale/addMedToStock?id=${id}`} className="addLink">
          Add New Medicine
        </Link>

        <Link to={`/wholeSale/wsMSAddToStock?id=${id}`} className="addLink">
          Add Medical supply
        </Link>
        <Link
          to={`/wholeSale/displayMedicalSupply?id=${id}`}
          className="addLink"
        >
          Medical supplies In stock
        </Link>
      </div>

      <div className="medicineStockDisplay">
        <MedicineStockDisplay userId={id} />
      </div>
    </div>
  );
};

export default MedicineForm;
