const woredaData = {
    "South Gondar Zone": ["Libokemekem",
    "Ebinat",
    "Fogera",
    "Simada",
    "Lay Gayint",
    "Farta",
    "Tach Gayint",
    "Dera",
    "Estie",
    "Debre Tabor",
    "Guna Begie Midir",
    "Addis Zemen",
    "Wereta",
    "Andabet",
    "Meketewa",
    "Nifas Mewcha",
    "Sedie Muja",
    "Mekane Eyesus"
    ],
    "North Wollo Zone": ["Bugna",
    "Woldiya City Administration",
    "Kobo City",
    "Gidan",
    "Meket",
    "Wadila",
    "Lasta",
    "Dawunt",
    "Raya Kobo",
    "Mersa City Administration",
    "Gubalafto",
    "Habiru",
    "Gazo",
    "Angot",
    "Lalibela City Administration"
    ],
    "South Wollo Zone": ["Delanta",
    "Around Dese",
    "Ambassel",
    "Magdala",
    "Teneta",
    "Kutaber",
    "Tehuledere",
    "Kalu",
    "Albuko",
    "Legambo",
    "Saynt Adjibar",
    "Kelala",
    "Jama",
    "Wereilu",
    "Wegde",
    "Mehal Sayint",
    "Legahida",
    "Werebabo",
    "Borena",
    "Kombolcha City Administration",
    "Mekane Selam City Administration",
    "Haik",
    "Wereilu City Administration"
    ],
    "North Shewa Zone": ["Menz Gera Midere",
    "Mida Woremo",
    "Antsokiyana Gemza",
    "Mojana Wadera",
    "Hagere Mariamna Kesem",
    "Debre Birhan",
    "Merhabetie",
    "Geshe",
    "Efratana Gidim",
    "Menz Mama Midir",
    "Termaber",
    "Kewet",
    "Angolallana Tera",
    "Asagirt",
    "Berehet",
    "Ankober",
    "Menjarna Shenkora",
    "Basona Werana",
    "Menz Keya Gebreal",
    "Menz Lalo Midir",
    "Siya Debrina Wayu",
    "Moretna Jiru",
    "Ensaro",
    "Shewarobit",
    "Ataye Town Administration",
    "Mehalmeda",
    "Alemketema Town Adminstration"
    ],
    "East Gojjam Zone": ["Gozamin",
    "Hulet Ej Enese",
    "Debre Elias",
    "Enbise Sar Midir",
    "Enarj Enawga",
    "Enemay",
    "Debay Telatgen",
    "Machakel",
    "Baso Liben",
    "Awabel",
    "Dejen",
    "Debre Markos",
    "Shebel Berenta",
    "Sinan",
    "Aneded",
    "Bibugn",
    "Goncha Siso Enese",
    "Mota City Administration",
    "Sede",
    "Dejen City Administration",
    "Bichena City Administration"
    ],
    "West Gojjam Zone": ["Semen Achefer",
    "Dega Damot",
    "Debub Achefer",
    "Wemberma",
    "Around Bahir Dar",
    "Yilmana Densa",
    "Semen Mecha",
    "Sekela",
    "Kuarit",
    "Dembecha",
    "Jabi Tehnan",
    "Around Burie",
    "Gonje Kolela",
    "Finote Selam City Administration",
    "Dembecha City Administration",
    "Burie City Administration",
    "Debub Mecha",
    "Adet Town Administration",
    "Merawi City Administration"
    ],
    "Waghimra Zone": ["Zikuala",
    "Abergele",
    "Around Sokota",
    "Dehana",
    "Gazgibla",
    "Sehala Seyemt",
    "Sekota City Administration",
    "Tsagibji"
    ],
    "Awi Zone": ["Dangila",
    "Ankesha Guagusa",
    "Jawi",
    "Banja Shekudad",
    "Guangua",
    "Fagita Lekoma",
    "Guagusa Shekudad",
    "Zigem",
    "Ayehu Guagusa",
    "Chagini",
    "Enjibara City Administration",
    "Dangla City Administration"
    ],
    "Oromiya-Special Zone": ["Dawa Chefe",
    "Dawa Harewa",
    "Jile Timuga",
    "Kemisse",
    "Artuma Fursi",
    "Bati",
    "Bati City Administration"
    ],
    "Bahir Dar Special Zone": ["Bahir Dar City Tana Subcity",
    "Fasilo",
    "Gishe Abay",
    "Hidar 11",
    "Belay Zeleke",
    "Ginbot 20",
    "Around Bahir Dar City"
    ],
    "Argoba Special Zone": ["Argobba Special"
    ],
    "Dessie Town Administration": ["Dessie City Menafesha Subcity",
    "Banbawuha",
    "Hote",
    "Arada",
    "Segnogebeya"
    ],
    "Gondar Town Special Zone": ["Gondar City Zobel Subcity",
    "Fasil",
    "Jantekel",
    "Arada",
    "Maraki",
    "Azezo Tseda"
    ],
    "North Gondar Zone": ["Adi Arkay",
     "Beyeda",
     "Debark",
     "Dabat",
     "Jan Amora",
     "Telemit",
     "Debark City Administration",
     "Dabat City Administration"
     ],
     "Central Gonder": ["Tegedie",
     "Lay Armachiho",
     "Wogera",
     "Gonder Zuriya",
     "Alefa",
     "Mirabbelesa",
     "Misrakbelesa",
     "Tachi Armachiho",
     "Takusa",
     "Kinfaz Begela",
     "Mirabdembiya",
     "Misrak Dembiya",
     "Chilga",
     "Aykel City Administration"
     ],
     "West Gonder": ["Merab Armachiho",
     "Quara",
     "Metema",
     "Midre Genet City Administration",
     "Gendewuha City",
     "Metema Yohannes City Administration"
     ]
     //this was amhara
      ,
     "Itang Special Zone": ["Itang"
    ],
     "Anywaa Zone": ["Abobo",
    "Gambela",
    "Gog",
    "Jor",
    "Gambela Zuriya",
    "Dima"
    ],
    "Nuer Zone": ["Jikawo",
    "Lare",
    "Akobo",
    "Wantawo",
    "Makuey"
    ],
    "Majang Zone": ["Godere", 
    "Mengeshi"
    ]
    //this was Gambela
    ,
    "Dire Dawa": ["Dire Dawa Administration"
    ]
    //this was diredewa
    ,
    Harari: ["Amir-Nur Woreda",
    "Abadir Woreda",
    "Shenkor Woreda",
    "Jinela Woreda",
    "Aboker Woreda",
    "Hakim Woreda",
    "Sofi Woreda",
    "Errer Woreda",
    "Dire-Teyara Woreda"
    ]
    //this was harari
    ,
    "Liben Zone": ["Filtu",
    "Dollo Addo",
    "Qarsadula",
    "Gura Damole",
    "Gura Baqasa",
    "Bokolmayo",
    "Deka Softu"
    ],
    "Afder Zone": ["Hargelle",
    "Cherati",
    "Dolobay",
    "Raaso",
    "Baarey",
    "Ceelkarri",
    "Iimey Galbeed",
    "Goro Bekeksa",
    "Qooxle",
    "Godgod"
    ],
    "Jarar Zone": ["Daroor",
     "Gunagado",
     "Bilcil Buur",
     "Gashamo",
     "Aware",
     "Araarso",
     "Dhagax-Buur",
     "Dhagax-Madow",
     "Birqod",
     "Dig",
     "Yoocaale",
     "Degehabur City Admnistration"
     ],
     "Nogob Zone": ["Gerbo",
     "Ayun",
     "Hor-Shagah",
     "Elweyn Town",
     "Dhuxun",
     "Xaraarey",
     "Segeg"
     ],
     "Erer Zone": ["Lagahida",
     "Qubi",
     "Waangay",
     "Fiqi",
     "Salaxaad",
     "Mayu-Muuqdheer",
     "Yaxoob",
     "Hamero"
     ],
     "Shebele Zone": ["Danan",
     "Ferfer",
     "Gode",
     "Kelafo",
     "Beercaano",
     "Gode Council",
     "Elelle",
     "Abaqorow",
     "East Eme",
     "Adadle",
     "Mustahil"
     ],
     "Fafan Zone": ["Awbare",
     "Jijiga",
     "Babille",
     "Gursum",
     "Harshin",
     "Tuli Guled",
     "Kebri Beyah",
     "Togochale City Admenistration",
     "Harawo",
     "Qooraan Mulla",
     "Harores",
     "Goljano",
     "Debub Jigjiga"
     ],
     "Korahe Zone": ["Kebri Dahar",
     "Shekosh",
     "Laas-Dhankayre",
     "Kudunbuur",
     "Dhooboweyn",
     "Shilaabo",
     "Marsin",
     "Higloley",
     "Boodaley",
     "Ceel-Ogaden",
     "Gogilo",
     "Qebridahar Citiy Administration"
     ],
     "Sitti Zone": ["Afdem",
     "Ayesha",
     "Shinile",
     "Dambel",
     "Me' Aso",
     "Erer",
     "Hadhagaale",
     "Geblalu",
     "Gotabike"
     ],
     "Dollo Zone": ["Daratole",
     "Lehel-Yucub",
     "Warder",
     "Bokh",
     "Danot",
     "Galladi",
     "Galxamur",
     "Warder City Administration"
     ],
     "Dawa Zone": ["Qadhaadhumo",
     "Hudet",
     "Moyale",
     "Mubarek"
     ]
     //this was Somali
     ,
     "Awsi Rasu": ["Afambo",
     "Dubti",
     "Ada'Ar",
     "Chifra",
     "Mille",
     "Asayita",
     "Elidar",
     "Geyreni",
     "Kori"
     ],
     "Kilbet Rasu": ["Abala",
     "Afdera",
     "Berhale",
     "Dallol",
     "Megale",
     "Bidu",
     "Erebti",
     "Kuneba"
     ],
     "Gabi Rasu": ["Canruka",
     "Awash Fentale",
     "Gewane",
     "Amibara",
     "Dulesa",
     "Awash Ketema Astedader",
     "Gelalo",
     "Argoba Liyu"
     ],
     "Fantena Rasu": ["Aura",
     "Ewa",
     "Golina",
     "Teru",
     "Yalo"
     ],
     "Hari Rasu": ["Dalifage",
     "Hadele Ele",
     "Telalak",
     "Dewe",
     "Simurobi Gele'Alo",
     "Yangudi"
     ],
     Semera: ["City3C1", "City3C2" ]// this was afar and semera is not done 
     ,
     "South Zone": ["Alaje",
     "Alamata",
     "Endamehoni",
     "Ofla",
     "Maichew",
     "Raya Azebo"
     ],
     "South East Zone": ["Enderta",
     "Samre",
     "Hintalo Wajirat",
     "Degua Tembien"
     ],
     "Central Zone": ["Abergele",
     "Adwa",
     "Enticho",
     "Mereb Lehe",
     "Werie Lehe",
     "Kola Tembien",
     "La'Ilay Maychew",
     "Naeder Adet",
     "Tahtay Maychew"
     ],
     "Mekele Special Zone": ["Mekele"],
     "West Zone": ["Kafta Humera",
     "Tsegede",
     "Welkait"
     ],
     "East Zone": ["Atsbi Wenberta",
     "Gulomahda",
     "Kilte Awulaelo",
     "Ganta Afeshum",
     "Hawzen",
     "Irob",
     "Saesi Tsaedaemba"
     ],
     "North West Zone": ["Asgede Tsimbela",
     "Medebay Zana",
     "Tahtay Koraro",
     "Tselemti",
     "La'Ilay Adiyabo",
     "Tahtay Adiyabo"
     ]
     //this was Tigray
     ,
     "Assosa Zone": ["Sherkole",
     "Bambasi",
     "Assosa",
     "Horazab",
     "Menge",
     "Oda Bildigilu",
     "Kurmuk",
     "Homosha",
     "Wereda And",
     "Wereda Hulet"
     ],
     "Kamashi Zone": ["Agalo Mite",
     "Kamashi",
     "Belo Jegonfoy",
     "Sadal",
     "Yasso"
     ],
     "Metekel Zone": ["Mandura",
     "Guba",
     "Bulen",
     "Wenbera",
     "Dibate",
     "Dangur",
     "Pawe"
     ],
     "Mao Ena Komo": ["Mao Ena Komo"]
     //this was Benishangul Gumuz
 ,
     "West Arsi": ["Shashamene",
     "Adaba",
     "Dodola",
     "Gadab Hasaasa",
     "Heeban Arssi",
     "Kofale",
     "Kokosa",
     "Wando",
     "Kore",
     "Nansabo",
     "Siraro",
     "Shalla",
     "Dodola City Administration",
     "Arsi Negele",
     "Arsi Negele City Administration"
     ],
     "Kelam Welega": ["Anfillo",
     "Dalle Sadi",
     "Dale Wabera",
     "Dembidolo Town",
     "Gawo Kebe",
     "Gidami",
     "Hawa Gelan",
     "Jimma Horo",
     "Lalo Kile",
     "Seyo",
     "Yemalogi Welele",
     "Chanka Woreda"
     ],
     "Arsi": ["Robe",
     "Seru",
     "Sire",
     "Tena",
     "Tiyo",
     "Guna",
     "Amigna",
     "Aseko",
     "Bale Gasgar",
     "Chole",
     "Digalu And Tijo",
     "Shanan Kolu",
     "Diksis",
     "Dodota",
     "Honkolo Wabe",
     "Gololcha",
     "Hitosa",
     "Jeju",
     "Lemu And Bilbilo",
     "Lode Hitosa",
     "Merti",
     "Munesa",
     "Sude",
     "Zewaydugda",
     "Shirka",
     "Bekoji City Administration"
     ],
     "West Bale": ["Agarfa",
     "Barbare",
     "Dallo Manna",
     "Goro",
     "Gurdamole",
     "Harana Buluk",
     "Madda Wolabu",
     "Gasera",
     "Sinana",
     "Dinsho",
     "Goba City",
     "Goba"
     ],
     "East Bale": ["Gololcha",
     "Legahida",
     "Seweyna",
     "Ginir",
     "Rayitu",
     "Dawe Kachin",
     "Dawe Serer",
     "Ginir City Administration"
     ],
     Borena: ["Dire",
     "Miyo",
     "Teltele",
     "Yabelo",
     "Dhasi",
     "Arero",
     "Moyale",
     "Dillo",
     "Guchi",
     "Dubluk",
     "Gomole",
     "Wachile",
     "Elowaye",
     "Yabelo City"
     ],
     Guji: ["Bore",
     "Dama",
     "Aga Wayu",
     "Gumi Eldalo",
     "Goro Dola",
     "Liben",
     "Uraga",
     "Adola Wayu City Administration",
     "Ana Sora",
     "Gerja",
     "Haro Walabu",
     "Odo Shakisotown",
     "Adola",
     "Saba Boru",
     "Shakiso City Administration",
     "Negele City Administration",
     "Wadera"
     ],
     "West Guji": ["Galana",
     "Dugda Dawa",
     "Bule Hora",
     "Qarcha",
     "Malka Sodo",
     "Soro Barrguda",
     "Bule Hora Town",
     "Abaya",
     "Hambela Wamena",
     "Birbirsa Kojowa"
     ],
     "East Hararghe": ["Kombolcha",
     "Jarso",
     "Gursum",
     "Babile",
     "Fedis",
     "Haremaya",
     "Kurfa Chele",
     "Kersa",
     "Meta",
     "Gorogutu",
     "Deder",
     "Melka Belo",
     "Bedeno",
     "Midaga Tola",
     "Chinaksen",
     "Girawa",
     "Golo Oda",
     "Meyu Muluke",
     "Goro Muti",
     "Haremaya City Administration",
     "Aweday City Administration",
     "Kumbi",
     "Deder City Administration",
     "Babile City Administration"
     ],
     "West Hararghe": ["Mieso",
     "Doba",
     "Tulo",
     "Mesela",
     "Chiro Ketema Asetedader",
     "Ancar",
     "Guba Koricha",
     "Habro",
     "Daro Labu",
     "Boke",
     "Oda Bultum",
     "Gemechis",
     "Chiro",
     "Bedesa Ketema Asetedader",
     "Gumbi Bordede",
     "Hawi Gudina",
     "Burka Dhintu"
     ],
     "Buno Bedele": ["Chawaka",
     "Dabohana",
     "Bedelle",
     "Around Bedele",
     "Gechi",
     "Didesa",
     "Borecha",
     "Chora",
     "Dega",
     "Makko"
     ],
     "East Shewa": ["Gimbichu",
     "Bora",
     "Dugda",
     "Adea",
     "Adama",
     "Adami Tulu",
     "Akeki",
     "Bosat",
     "Fentale",
     "Liban Chukala",
     "Lume",
     "Bora"
     ],
     "Ilu Abba Bora": ["Darimu",
     "Alge Sachi",
     "Yayo",
     "Mettu",
     "Alle",
     "Bure",
     "Nono Sele",
     "Doranni",
     "Bilo Nopa",
     "Hurumu",
     "Didu",
     "Halu",
     "Metu City",
     "Bacho"
     ],
     "West Shewa": ["Abuna Ginde Beret",
     "Adaa Barga",
     "Around Ambo",
     "Bako Tibe",
     "Cheliya",
     "Dano",
     "Dendi",
     "Ejerie",
     "Elfata",
     "Ginde Beret",
     "Jeldu",
     "Jibat",
     "Meta Robi",
     "Midakegn",
     "Nono",
     "Dire Enchini",
     "Toke Kutaye",
     "Cobii",
     "Meta Walqite",
     "Liban Jawwi",
     "Ejersa Lafo",
     "Ilu Galan"
     ],
     "North Shewa": ["Abichuna Gne'A",
     "Aleltu",
     "Degem",
     "Dera",
     "Fiche",
     "Gerar Jarso",
     "Hidabu Abote",
     "Jida",
     "Wuchale",
     "Yaya Gulele",
     "Debra Libanos",
     "Kembibit",
     "Kuyu",
     "Wara Jarso"
     ],
     "South West Shewa": ["Ameya",
     "Becho",
     "Dawo",
     "Ilu",
     "Goro",
     "Kersa Malima",
     "Seden Sodo",
     "Sodo Dachi",
     "Tole",
     "Waliso",
     "Wonchi"
     ],
     "Oromia Special Zone Surrounding Finfinne": ["Sululta",
     "Berak",
     "Mulo",
     "Sebeta Hawas",
     "Sendafa Bake",
     "Walmara"
     ],
     "Adama Special Zone": ["Aba Geda",
     "Bale",
     "Boku",
     "Denibel",
     "Dibe",
     "Logo"
     ],
     Jimma: ["Gera",
     "Gomma",
     "Gumay",
     "Seka Chekorsa",
     "Mana",
     "Sokoru",
     "Sigimo",
     "Botor Tolay",
     "Mancho",
     "Omo Beyan",
     "Agaro Town",
     "Chora Botor",
     "Dedo",
     "Limu Kosa",
     "Limu Saka",
     "Omo Nada",
     "Tiroafeta",
     "Setema",
     "Shebe Senbo",
     "Kersa",
     "Nano Benja"
     ],
     "West Wollega": ["Mana Sibu",
     "Nejo",
     "Gimbi",
     "Lalo Asabi",
     "Kiltu Kara",
     "Bojidimaji",
     "Guliso",
     "Jarso",
     "Kondala",
     "Boji Chokorsa",
     "Babo Gambel",
     "Yubdo",
     "Ganji",
     "Haru",
     "Nole Kaba",
     "Begi",
     "Gimbi Town",
     "Seyo Nole",
     "Ayira",
     "Homa",
     "Nejo Town",
     "Mandi Town",
     "Leta Sibu"
     ],
     "East Wollega": ["Haro Limmu",
     "Leka Dulecha",
     "Ebantu",
     "Jimma Arjo",
     "Limu",
     "Nunu Kumba",
     "Sasiga",
     "Sibu Sire",
     "Wama Hagalo",
     "Wayu Tuqa",
     "Kiramu",
     "Gida Ayana",
     "Gudaya Bila",
     "Bonayaboshe",
     "Guto Gida",
     "Gobu Seyo",
     "Diga",
     "Anger Gute Town"
     ],
     "Horo Guduru Wollega": ["Horo",
     "Horo(Shambu)",
     "Guduru",
     "Hababoguduru",
     "Abaychoman",
     "Jimmagenate",
     "Jimarare",
     "Jardaga Jarte",
     "Amuru",
     "Abedongoro",
     "Chemon Guduru",
     "Horo Buluk"
     ],
     "Asela Liyu Zone": ["Assela"],
     "Bishoftu Liyu Zone": ["Bishoftu City Adminstration"],
     Burayu: ["Gafarsa Guje",
     "Burayu Gafarsa",
     "Katta Arera",
     "Malka Nonno",
     "Ganda Guje"
   ],
     "Dukam Liyu Zone": ["Dukam"],
     "Gelan Liyu Zone": ["Galan"],
     "Laga Tafo Laga Dadi": ["Laga Tafo Laga Dadhi"],
     "Nekemte Liyu Zone": ["Nekemte"],
     "Jimma Liyu": ["Jimma"],
     Sabata: ["Sebeta"],
     "Shashamane Liyu Zone": ["Shashamane City Adminstration"],
     Sululta: ["Sululta"],
     "Robe Liyu Zone": ["Robe City Adminstration"],
     "Ambo City Admin": ["Ambo"],
     "Batu Special Zone": ["Batu City Adminstration"],
     "Modjo Liyu Zone": ["Modjo"],
     "Holeta Liyu Zone": ["Holeta"],
     "Woliso Special Zone": ["Woliso"],
     "Bishan Guracha Liyu Zone": ["Bishan Guracha City Adminstration"]
     // this  was oromia
     , 
    Hawassa: ["Tabor","Addis Ketama"],
    Sidama: ["Awasatown",
    "Aleta Wendo",
    "Arbegona",
    "Bensa",
    "Around Hawassa",
    "Bensa",
    "Around Bona",
    "Boricha",
    "Bursa",
    "Chere",
    "Aletachuko",
    "Dale",
    "Dara",
    "Gorche",
    "Hula",
    "Loko Abaya",
    "Malga",
    "Shebedino",
    "Wensho",
    "Wondo Genet"
    ]
    //this was sidama
    ,
    "Addis Ketema": ["Woreda 02",
    "Woreda 03",
    "Woreda 04",
    "Woreda 05",
    "Woreda 06",
    "Woreda 07",
    "Woreda 08",
    "Woreda 09",
    "Woreda 10",
    "Woreda 01",
    "Woreda 11",
    "Woreda 12",
    "Woreda 13",
    "Woreda 14"
    ],
    Arada: ["Woreda 01",
    "Woreda 02",
    "Woreda 03",
    "Woreda 04",
    "Woreda 05",
    "Woreda 06",
    "Woreda 07",
    "Woreda 08",
    "Woreda 09",
    "Woreda 10"
    ],
    Gullele: ["Woreda 01",
    "Woreda 02",
    "Woreda 03",
    "Woreda 04",
    "Woreda 05",
    "Woreda 06",
    "Woreda 07",
    "Woreda 08",
    "Woreda 09",
    "Woreda 10"
    ],
    Kirkos: ["Woreda 01",
    "Woreda 02",
    "Woreda 03",
    "Woreda 04",
    "Woreda 05",
    "Woreda 06",
    "Woreda 07",
    "Woreda 08",
    "Woreda 09",
    "Woreda 10",
    "Woreda 11"
    ],
    "Kolfe Keranio": ["Woreda 01",
    "Woreda 02",
    "Woreda 03",
    "Woreda 04",
    "Woreda 05",
    "Woreda 06",
    "Woreda 07",
    "Woreda 08",
    "Woreda 09",
    "Woreda 10",
    "Woreda 11",
    "Woreda 12",
    "Woreda 13",
    "Woreda 14",
    "Woreda 15"
    ],
    Lideta: ["Woreda 01",
    "Woreda 02",
    "Woreda 03",
    "Woreda 04",
    "Woreda 05",
    "Woreda 06",
    "Woreda 07",
    "Woreda 08",
    "Woreda 09",
    "Woreda 10"
    ],
    "Nifas Silk-Lafto": ["Woreda 01",
    "Woreda 02",
    "Woreda 03",
    "Woreda 04",
    "Woreda 05",
    "Woreda 06",
    "Woreda 07",
    "Woreda 08",
    "Woreda 09",
    "Woreda 10",
    "Woreda 11",
    "Woreda 12",
    "Woreda 13"
    ],
     Yeka: ["Woreda 01",
     "Woreda 02",
     "Woreda 03",
     "Woreda 04",
     "Woreda 05",
     "Woreda 06",
     "Woreda 07",
     "Woreda 08",
     "Woreda 09",
     "Woreda 10",
     "Woreda 11",
     "Woreda 12",
     "Woreda 13",
     "Woreda 14"
    ],
     Bole: ["Woreda 01",
     "Woreda 02",
     "Woreda 03",
     "Woreda 04",
     "Woreda 05",
     "Woreda 06",
     "Woreda 07",
     "Woreda 08",
     "Woreda 09",
     "Woreda 10",
     "Woreda 11",
     "Woreda 12",
     "Woreda 13",
     "Woreda 14"
    ],
    "Lemi Kura": ["Woreda 01",
    "Woreda 02",
    "Woreda 03",
    "Woreda 04",
    "Woreda 05",
    "Woreda 06",
    "Woreda 07",
    "Woreda 08",
    "Woreda 09",
    "Woreda 10",
    "Woreda 11",
    "Woreda 12",
    "Woreda 13",
    "Woreda 14"
    ],
    "Akaki Kality": ["Woreda 01",
    "Woreda 02",
    "Woreda 03",
    "Woreda 04",
    "Woreda 05",
    "Woreda 06",
    "Woreda 07",
    "Woreda 08",
    "Woreda 09",
    "Woreda 10",
    "Woreda 11",
    "Woreda 12",
    "Woreda 13"
    ]
    // this was Addis Ababa
    ,
    "Silti Zone": ["Alicho Werero",
   "Dalocha",
   "Wulbareg",
   "Lanfro",
   "Mirab Azernet Berbere",
   "Misraq Azernet Berbere",
   "Sankurra",
   "Silte",
   "Hulbareg",
   "Worabe Town Adminstration",
   "Misrak Silite",
   "Kebet Ketema Astedader"
    ],
    "Gamo Gofa Zone": ["empty not added yet "],//not added yet 
    "Bench Maji Zone": ["Bero",
   "Debub Bench",
   "Guraferda",
   "Maji",
   "Meinit Goldiya",
   "Meinit Shasha",
   "Mizan Aman Town",
   "Semien Bench Maji",
   "Shay Bench",
   "Sheko",
   "Surma",
   "Dizu Gedi"
    ],
    "South Omo Zone": ["Debub Ari",
   "Bena Tsemay",
   "Hamer",
   "Semen Ari",
   "Male",
   "Nyangatom",
   "Selamago",
   "Dasenech",
   "Jinka City Adminstration"
    ],
    "Bench Sheko Zone": ["empty not added yet "],//not added yet 
    "Wolayita Zone": ["Offa",
    "Humbo",
    "Damot Gale",
    "Boloso Bombe",
    "Boloso Sore",
    "Damot Pulasa",
    "Damot Sore",
    "Damot Weyde",
    "Diguna Fango",
    "Kindo Didaye",
    "Kawo Koysha",
    "Sodo City Administration",
    "Around Sodo",
    "Abeshge",
    "Enemorina Eaner",
    "Areka City Administration",
    "Boditi Town",
    "Bayra Koysha",
    "Hobicha Abaya",
    "Abala Abaya"
    ],
    "Dawro Zone": ["Gena Bosa",
    "Isara",
    "Loma",
    "Mareka",
    "Tocha",
    "Tarcha Ketema Astedader",
    "Mari Mansa",
    "Gena",
    "Tarcha Zuriya",
    "Disa",
    "Kechi"
    ],
    "Gamo Zone": ["Kucha Alfa",
    "Boreda",
    "Mirab Abaya",
    "Azo",
    "Chencha",
    "Dermalo",
    "Kemba",
    "Marta Garda",
    "Bonke",
    "Baba Gacho",
    "Arbaminch Zuriya",
    "Arba Minch"
    ],
    "Gofa Zones": ["Melo Koza",
    "Melo Gada",
    "Denba Gofa",
    "Geze Gofa",
    "Bulki Ketema Asetedader",
    "Oyida",
    "Sawula",
    "Zala",
    "Uba Debretsehay"
    ],
    "Gedeo Zone": ["Bule",
    "Dila Town",
    "Gedeb",
    "Dila Zuriya",
    "Wenago",
    "Kochere",
    "Yirgachefe"
    ],
    "Gurage Zone": ["Butajira Town",
    "Cheha",
    "Endegagn",
    "Ezha",
    "Geta",
    "Kebena",
    "Gedebano Gutazer Welene",
    "Mareko",
    "Meskane",
    "Enemor Ena Ener",
    "Soddo",
    "Wolkite Town Adminstration",
    "Butajira City Administation",
    "Endiber City Administration",
    "Abashige",
    "Gumer",
    "Mihurina Kilil"
    ],
    "Hadiya Zone": ["Ana Lemo",
    "Duna",
    "Gibe",
    "Soro Woreda",
    "Gembora",
    "Hosaena Town",
    "Limu Woreda",
    "Mirab Badawacho",
    "Misha",
    "Misraq Badawacho",
    "Mirab Sooro Woreda",
    "Siraro Woreda",
    "Shashogo",
    "Shone City Administration",
    "Ameka"
    ],
    "Keffa Zone": ["Bita",
    "Bonga Town",
    "Chena Woreda",
    "Cheta Woreda",
    "Decha",
    "Gesha Deka",
    "Gewata",
    "Misha Woreda",
    "Ginbo",
    "Sayilem",
    "Telo",
    "Siraro Woreda",
    "Amekka Woreda",
    "Menjiwo",
    "Adiyo",
    "Sheshoendey",
    "Goba",
    "Wacha City Administrator"
    ],
    "Kembata Tembaro Zone": ["Decha",
    "Kedida Gamela",
    "Tembaro",
    "Doyogena",
    "Durame City Adminstration",
    "Hadero Tunto",
    "Kacha Bira",
    "Yirgachefe",
    "Angacha",
    "Damboya",
    "Hadaro City Adminstration",
    "Shinshicho City",
    "Adiloo Zuriya"
    ],
    "Sheka Zone": ["Anderacha",
    "Masha",
    "Yeki",
    "Tepi City Adminstration"
    ],
    "Independent Districts": ["Alaba Special Woreda",
    "Amaro Special Woreda",
    "Alle Special Woreda",
    "Basketo Special Woreda",
    "Burji Special Woreda",
    "Dirashe Special Woreda",
    "Konso Special Woreda",
    "Konta Special Woreda"
    ],
    Yem: ["Yem Liyu"],
    "Hawassa City Admnistration": ["Addis Ketema",
    "Bahel Adarash",
    "Haik Dar",
    "Hawela Tula",
    "Mehal Ketema",
    "Meneharya",
    "Misrak",
    "Tabor"
    ],
    "Dilla": ["Dilla Bedecha Town",
    "Dilla Sessa Town",
    "Dilla Harowelabo Town"
    ],
    "Segen Akababi Hizboch": ["empty not added yet "],//not added yet 
    Halaba: ["Halaba Kulito",
    "Wera Dijo",
    "Atote Ule",
    "Wera"
    ],
    Basketo: ["Basketo"],
    "Konta Liyu": ["Konta Liyu"],
    Konso: ["Segen Zuriya",
    "Karate Zuria",
    "Kena",
    "Karate City Admin"
    ]
    // this was Southern Nations Nationalities And People's Region
    ,
    Dawuro: ["Tercha City Administration"],
     
   };
   export default woredaData;