import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ResultsComponent from "../searchPages/ResultsComponent";
import "./css/displayFavorites.css";

const ListFavorites = () => {
  const location = useLocation();
  const { response, userId } = location.state || {};
  console.log("🚀 ~ ListFavorites ~ response:", response);

  const [message, setMessage] = useState("");
  const [availableDetails, setAvailableDetails] = useState([]);
  const [notAvailableDetails, setNotAvailableDetails] = useState([]);

  useEffect(() => {
    if (!response) {
      // Handle the case where there is no response
      setMessage("No response received.");
      setAvailableDetails([]);
      setNotAvailableDetails([]);
    } else if (response.error) {
      // Handle the error case
      setMessage(response.error);
      setAvailableDetails([]);
      setNotAvailableDetails([]);
    } else {
      // Process the response if there’s no error
      setMessage(response.message);

      // Split foundDetails into available and not available
      const details = response.data.found || [];
      const available = details.filter(
        (detail) => detail.availability_status === "Available"
      );
      const notAvailable = details.filter(
        (detail) => detail.availability_status === "Not Available"
      );

      setAvailableDetails(available);
      setNotAvailableDetails(notAvailable);
    }
  }, [response]);

  // Use response and userId as needed
  console.log("Received response:", response);
  console.log("Received userId:", userId);
  console.log("🚀 ~ ListFavorites ~ message:", message);
  console.log("🚀 ~ ListFavorites ~ availableDetails:", availableDetails);
  console.log("🚀 ~ ListFavorites ~ notAvailableDetails:", notAvailableDetails);

  return (
    <div className="container">
      <section className="section">
        {availableDetails.length > 0 && (
          <>
            <h1>This WholeSales Have New Products</h1>
            {availableDetails.map((detail, index) => (
              <ResultsComponent
                key={index}
                wholeSale={detail}
                ownId={userId.params}
              />
            ))}
          </>
        )}
      </section>
      <span className="separator">|</span>
      <section className="section">
        {notAvailableDetails.length > 0 && (
          <>
            <h1>Not New Update</h1>
            {notAvailableDetails.map((detail, index) => (
              <ResultsComponent
                key={index}
                wholeSale={detail}
                ownId={userId.params}
              />
            ))}
          </>
        )}
      </section>
      <h1>Evaluate Your suppliers:</h1>
      <p>Formal registration</p>
      <p>Reference checks with past clients and international agencies </p>
      <p>Test purchases in small quantities</p>
      <p>Informal local information gathering.</p>
    </div>
  );
};

export default ListFavorites;
