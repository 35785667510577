import React, { useState, useEffect, useRef } from "react";
import "../css/PopupResonForDiduction.css"; // Make sure to style your component accordingly

const PopupResonForDiduction = ({ isOpen, options, onClose, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Close the popup if click is outside of it
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleConfirm = () => {
    if (selectedOption) {
      onSelect(selectedOption); // Pass the selected option to the parent component
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content" ref={popupRef}>
        <h2>Select Reason for Removal</h2>
        <ul className="options-list">
          {options.map((option, index) => (
            <li
              key={index}
              className={`option-item ${
                selectedOption === option ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
        <button onClick={handleConfirm}>Confirm</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default PopupResonForDiduction;