import React ,{useState}from 'react';
import "./myLogin.css"
function Loginform({ Login, error}) {
  const [details, setDetails] = useState({ name: "", email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const submitHandler = (e) => {
    e.preventDefault();
    Login(details);
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="form-inner">
        <h2>login</h2>
        {error !== "" ? <div className="error">error</div> : ""}
        <div className="form-group">
          <label htmlFor="name">Admin Name</label>
          <input
            type="text"
            name="name"
            id="name"
            onChange={(e) => setDetails({ ...details, name: e.target.value })}
            value={details.name}
          />
        </div>

        <div className="form-group ">
          <label htmlFor="email">Email: </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={(e) => setDetails({ ...details, email: e.target.value })}
            value={details.email}
          />
        </div>

        <div className="form-group password-container">
          <label htmlFor="password">Password: </label>
          <input
            type={showPassword ? "text" : "password"} // Toggle input type based on state
            name="password"
            id="password"
            onChange={(e) =>
              setDetails({ ...details, password: e.target.value })
            }
            value={details.password}
          />
          {/* Eye icon to toggle password visibility */}
          <span
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)} // Toggle the password visibility state
          >
            {showPassword ? "👁️" : "👁️‍🗨️"} {/* Change icon based on state */}
          </span>
        </div>

        <div>
          <input type="submit" value="Login" />
        </div>
        <h1>
          If not an admin please contact as <>0935968129</> please feel free to
          call as any time{" "}
        </h1>
      </div>
    </form>
  );
}
export default Loginform;