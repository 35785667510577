import Axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UserLocationSelector from "../../../helpers/locationinputs/components/adressZoneState";
import CoordinateInput from "../../../helpers/locationinputs/components/coordintesInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import addressSchema from "../../../helpers/validations/signinInputs/signUpValidation/adressInput";
import "./CSS/adressUpdate.css";

const UpdateAdressPh = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  console.log("🚀 ~ UpdateAdress ~ id:", id);

  const [formData, setFormData] = useState({
    region: "",
    zone: "",
    woreda: "",
    kebele: "",
    house_no: "",
    pobox: "",
    street: "",
    latitude: "",
    longitude: "",
    userId: id,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(addressSchema),
    defaultValues: formData,
  });

  const updateCoordinates = (latitude, longitude) => {
    setFormData({
      ...formData,
      latitude,
      longitude,
    });
  };

     useEffect(() => {
       // Update the form values when formData changes
       setValue("region", formData.region);
       setValue("zone", formData.zone);
       setValue("woreda", formData.woreda);
       setValue("kebele", formData.kebele);
       setValue("street", formData.street);
       setValue("house_no", formData.house_no);
       setValue("pobox", formData.pobox);
       setValue("latitude", formData.latitude);
       setValue("longitude", formData.longitude);
     }, [formData, setValue]);
  const handleLocationSelect = (location) => {
    setFormData({
      ...formData,
      region: location.region,
      zone: location.zone,
      woreda: location.woreda,
    });
    setValue("region", location.region);
    setValue("zone", location.zone);
    setValue("woreda", location.woreda);
  };

  Axios.defaults.withCredentials = true;
  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log("Form data submitted:", data);
    const url = `${process.env.REACT_APP_API_URL}/pharmacy/profile/updateadress`;

    Axios.put(url, data)
      .then((res) => {
        console.log("Request sent successfully");
        if (res.data.Status === "Success") {
          console.log("Update successful");
          navigate(`/pharmacy/phProfile?id=${id}`);
        } else {
          alert("Unable to update address");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred during the update");
      });
  };

  return (
    <div className="container">
      <h2>Update Address Form</h2>
      <Link to={`/pharmacy/phAdressView?id=${id}`}>
        <button className="checkAdress">
          Check my address
        </button>
      </Link>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UserLocationSelector onLocationSelect={handleLocationSelect} />

        <div>
          <label>Kebele:</label>
          <input type="text" {...register("kebele")} />
          <p>{errors.kebele?.message}</p>
        </div>
        <div>
          <label>Street Name:</label>
          <input type="text" {...register("street")} />
          <p>{errors.street?.message}</p>
        </div>
        <div>
          <label>House Number:</label>
          <input type="text" {...register("house_no")} />
          <p>{errors.house_no?.message}</p>
        </div>
        <div>
          <label>PO Box:</label>
          <input type="text" {...register("pobox")} />
          <p>{errors.pobox?.message}</p>
        </div>

        <CoordinateInput onCoordinatesUpdate={updateCoordinates} />

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default UpdateAdressPh;
