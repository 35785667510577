/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./cssforpath/firstPage.css"; // Import your CSS file
import bluePillsImg from "../pics/bluePills.jpeg";
import testimonialsImg from "../pics/image1.jpeg";
import test2 from "../helpers/logo/Untitled1.svg";

const FirstPage = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const companyName = `${process.env.REACT_APP_COMPANYNAME}`;
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedValue(value);
    setIsButtonDisabled(value !== "ethiopia");
  };

  return (
    <>
      <div className="Navbar">
        <span className="nav-logo">
          <img className="logo" src={test2} alt="" />
        </span>
        <div className={`nav-items ${isOpen ? "open" : ""}`}>
          <a href="/">Home</a>
          <a href="#about">About</a>
          <a href="#contact">Contact</a>
          <select value={selectedValue} onChange={handleSelectChange}>
            <option value="">Country</option>
            <option value="ethiopia">ETHIOPIA</option>
            <option value="kenya">KENYA</option>
            <option value="sudan">SUDAN</option>
            <option value="eritrea">ERITREA</option>
          </select>
          <Link to="/login" className="nav-link">
            <button disabled={isButtonDisabled}>Login</button>
          </Link>
          <Link to="/signUp" className="nav-link">
            <button disabled={isButtonDisabled}>Sign Up</button>
          </Link>
        </div>
        <div
          className={`nav-toggle ${isOpen ? "open" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bar"></div>
        </div>
      </div>
      <div className="body">
        {/* Hero Section */}
        <header id="home">
          <div className="hero-content">
            <img src={bluePillsImg} alt="Hero Image" className="hero-img" />
            <div className="hero-text">
              <h1>Welcome to {companyName}</h1>
              <h2>Connecting Pharmacies with Trusted Wholesale Suppliers</h2>
              <p>
                {companyName} is a revolutionary e-commerce platform designed to
                streamline the pharmaceutical supply chain. Our mission is to
                connect pharmacies with reliable wholesale suppliers, providing
                a seamless, efficient, and secure way to manage your
                pharmaceutical orders. Whether you're a small independent
                pharmacy or a large chain, {companyName} offers the tools you
                need to ensure you have the right products at the right time.
              </p>
            </div>
          </div>
        </header>

        {/* About Us Section */}
        <section id="about">
          <h2>About Us</h2>
          <p>
            At {companyName}, we understand the complexities and challenges of
            managing pharmaceutical supplies. Our platform is built by
            professionals with extensive experience in the pharmaceutical
            industry, ensuring that every feature is tailored to meet your
            needs. From product search and comparison to order tracking and
            delivery updates, {companyName} simplifies every step of the
            process.
          </p>
        </section>

        {/* Features Section */}
        <section id="features">
          <h2>Why Choose {companyName}?</h2>
          <ul>
            <li>
              <strong>Extensive Product Range:</strong> Access a comprehensive
              database of pharmaceutical products from trusted suppliers.
            </li>
            <li>
              <strong>Easy Ordering:</strong> Place orders with a few clicks,
              and enjoy flexible payment options.
            </li>
            <li>
              <strong>Real-Time Tracking:</strong> Monitor your orders with
              real-time updates and ensure timely deliveries.
            </li>
            <li>
              <strong>Secure Transactions:</strong> Our platform uses advanced
              security measures to protect your data and transactions.
            </li>
            <li>
              <strong>Regulatory Compliance:</strong> Ensure your pharmacy meets
              all regulatory requirements with our built-in compliance tools.
            </li>
          </ul>
        </section>

        {/* Testimonials Section */}
        <section id="testimonials">
          <div className="section-content">
            <img
              src={testimonialsImg}
              alt="Testimonials"
              className="section-img"
            />
            <div className="section-text">
              <h2>What Our Users Say</h2>
              <p>
                Discover how {companyName} has transformed the supply chain
                management for pharmacies just like yours. Our users appreciate
                the convenience, efficiency, and reliability that {companyName}
                brings to their daily operations.
              </p>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section id="contact">
          <h2>Join {companyName} Today</h2>
          <p>
            Ready to experience the future of pharmaceutical supply management?
            Sign up now and take the first step towards a more efficient and
            reliable way to manage your pharmacy's inventory.
          </p>
        </section>

        {/* Footer */}
        <footer>
          <p>
            Contact Information: Phone number: 0935968129, email address:
            Naolk35@gmail.com, and physical address.
          </p>
          <p>
            <Link to="/privacyPolicy">Privacy Policy</Link> |
            <a href="#terms-of-service">Terms of Service</a> |
            <a href="#help-center">Help Center</a>
          </p>
          {/* <p>Follow us on social media: </p> */}
          <p>© {companyName}. All rights reserved</p>
        </footer>
      </div>
    </>
  );
};

export default FirstPage;
