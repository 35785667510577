import Axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ThesigninpageUploadws = () => {
  const location = useLocation();
  const data = location.state?.data;
  const userId = data;
  console.log("id passed for the upload component", userId);

  const [licenceForTb, setLicenceForTb] = useState(null);
  const [licenceForGibir, setLicenceForGibir] = useState(null);
  const [error, setError] = useState("");

  const handleFile1Change = (event) => {
    const file1 = event.target.files[0];
    if (file1) {
      if (!isValidFileType(file1) || !isValidFileSize(file1)) {
        setError(
          "Invalid licence. Please select a valid file (JPEG, JPG, PNG, GIF, or PDF) less than 1MB."
        );
        setLicenceForTb(null);
      } else {
        // Change the file name here (e.g., appending a unique identifier)

        const newFileName = generateUniqueFileName(file1.name, "LFTB");
        const renamedFile = new File([file1], newFileName, {
          type: file1.type,
        });
        setLicenceForTb(renamedFile);
        setError("");
      }
    }
  };

  const handleFile2Change = (event) => {
    const file2 = event.target.files[0];
    if (file2) {
      if (!isValidFileType(file2) || !isValidFileSize(file2)) {
        setError(
          "Invalid Revenue. Please select a valid file (JPEG, JPG, PNG, GIF, or PDF) less than 1MB."
        );
        setLicenceForGibir(null);
      } else {
        // Change the file name here (e.g., appending a unique identifier)
        const newFileName = generateUniqueFileName(file2.name, "LFGB");
        const renamedFile = new File([file2], newFileName, {
          type: file2.type,
        });
        setLicenceForGibir(renamedFile);
        setError("");
      }
    }
  };

  const isValidFileType = (file) => {
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "application/pdf",
    ];
    return allowedTypes.includes(file.type);
  };
  const isValidFileSize = (file) => {
    return file.size <= 1024 * 1024 * 2; // 1MB limit
  };
  const generateUniqueFileName = (originalName, identifier) => {
    // Create a unique file name by combining date, username, and identifier
    const timestamp = Date.now();
    const fileExtension = originalName.split(".").pop();
    // Replace with the actual username
    console.log(
      "in the front end this will be its name",
      identifier,
      userId,
      timestamp
    );
    return `${identifier}_${userId}_${timestamp}.${fileExtension}`;
  };
  Axios.defaults.withCredentials = true;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!licenceForTb || !licenceForGibir) {
      setError("Please select both files to upload.");
      return;
    }
    // Send the form data to the server
    const formData = new FormData();
    formData.append("licenceForTbws", licenceForTb);
    formData.append("licenceForGibirws", licenceForGibir);
    formData.append("userId", userId);
    console.log(
      "licenceForTbws",
      licenceForTb,
      "licenceForGibirws",
      licenceForGibir
    );
    const submitUrl = `${process.env.REACT_APP_API_URL}/wholesale/register/uploads`;
    try {
      const res = await Axios.post(submitUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Request sent successfully");
      if (res.data.Status === "Success") {
        console.log("Registration successful");
        navigate("/login");
      } else {
        setError("Unable to register");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred during registration. Please try again.");
    }
  };

  return (
    <div className="container">
      <h2>Upload Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            Licence for Gibir:
            <input type="file" onChange={handleFile1Change} />
          </label>
        </div>
        <div className="form-group">
          <label>
            Licence for TB:
            <input type="file" onChange={handleFile2Change} />
          </label>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};
export default ThesigninpageUploadws;
