import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./css/supplyInput.css";

const MedicalSupplyForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  console.log("🚀 ~ MedicalSupplyForm ~ id:", id);

  const [formData, setFormData] = useState({
    productName: "",
    brandName: "",
    category: "",
    batchNumber: "",
    quantityInStock: "",
    unitMeasurement: "",
    expirationDate: "",
    price: "",
    dimensions: "",
    material: "",
    sizeOptions: "",
    sterilizationStatus: "",
    quantityPerPack: "",
    usage: "",
    indications: "",
    additionalFeatures: "",
    regulatoryCertifications: "",
    quota: "",
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("🚀 ~ handleSubmit ~ formData:", formData);
    try {
      const submitUrl = `${process.env.REACT_APP_API_URL}/wholesale/msUploadNewStock?id=${id}`;
      const response = await axios.post(submitUrl, formData);
      if (response.status === 200) {
        // Clear the form after successful response
        setFormData({
          productName: "",
          brandName: "",
          category: "",
          batchNumber: "",
          quantityInStock: "",
          unitMeasurement: "",
          expirationDate: "",
          price: "",
          dimensions: "",
          material: "",
          sizeOptions: "",
          sterilizationStatus: "",
          quantityPerPack: "",
          usage: "",
          indications: "",
          additionalFeatures: "",
          regulatoryCertifications: "",
          quota: "",
        });
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <div className="form-wrapper">
      <h2>Medical Supply Entry Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Product Name</label>
          <input
            type="text"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Brand Name</label>
          <input
            type="text"
            name="brandName"
            value={formData.brandName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Category</label>
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
            required
          >
            <option value="">Select Category</option>
            <option value="Wound Care">Wound Care</option>
            <option value="Diagnostic Equipment">Diagnostic Equipment</option>
            <option value="Surgical Instruments">Surgical Instruments</option>
            <option value="Infection Control">Infection Control</option>
            <option value="Respiratory Care">Respiratory Care</option>
            <option value="Intravenous Products">Intravenous Products</option>
            <option value="Mobility Aids">Mobility Aids</option>
            <option value="Personal Protective Equipment">PPE</option>
            <option value="Laboratory Supplies">Laboratory Supplies</option>
            <option value="Orthopedic Supplies">Orthopedic Supplies</option>
          </select>
        </div>
        <div className="form-group">
          <label>Batch Number</label>
          <input
            type="text"
            name="batchNumber"
            value={formData.batchNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Quantity in Stock</label>
          <input
            type="number"
            name="quantityInStock"
            value={formData.quantityInStock}
            onChange={handleChange}
            required
            min="1"
          />
        </div>
        <div className="form-group">
          <label>Unit of Measurement</label>
          <input
            type="text"
            name="unitMeasurement"
            value={formData.unitMeasurement}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Expiration Date</label>
          <input
            type="date"
            name="expirationDate"
            value={formData.expirationDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Price</label>
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Dimensions</label>
          <input
            type="text"
            name="dimensions"
            value={formData.dimensions}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Material</label>
          <input
            type="text"
            name="material"
            value={formData.material}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Size Options</label>
          <input
            type="text"
            name="sizeOptions"
            value={formData.sizeOptions}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Sterilization Status</label>
          <select
            name="sterilizationStatus"
            value={formData.sterilizationStatus}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Sterile">Sterile</option>
            <option value="Non-Sterile">Non-Sterile</option>
          </select>
        </div>
        <div className="form-group">
          <label>Quantity Per Pack</label>
          <input
            type="number"
            name="quantityPerPack"
            value={formData.quantityPerPack}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Quota</label>
          <input
            type="number"
            name="quota"
            value={formData.quota}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Usage</label>
          <select name="usage" value={formData.usage} onChange={handleChange}>
            <option value="">Select</option>
            <option value="Single-Use">Single-Use</option>
            <option value="Reusable">Reusable</option>
          </select>
        </div>
        <div className="form-group">
          <label>Indications</label>
          <textarea
            name="indications"
            value={formData.indications}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Additional Features</label>
          <textarea
            name="additionalFeatures"
            value={formData.additionalFeatures}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Regulatory Certifications</label>
          <textarea
            name="regulatoryCertifications"
            value={formData.regulatoryCertifications}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default MedicalSupplyForm;
